import React, { Component } from 'react';
import { ChevronLeft28Regular as ChevronLeftIcon } from '@fluentui/react-icons';
import styled from 'styled-components';
import SubcategoryItem from './SubcategoryItem';

const Wrapper = styled.div`
  background-color: #f9f9f9;
  height: 100%;
  width: 100%;
`;

const ActionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px 10px;
`;

const Header = styled.div`
  width: 100%;
  padding: 10px 20px 20px 20px;
`;

const Content = styled.div`
  width: 100%;
  padding: 0 20px;
`;

export default class StudentCategory extends Component {
  render() {
    return (
      <Wrapper>
        <ActionHeader>
          <button
            onClick={this.toggleModal}
            className="ln-btn ln-icon-btn basic"
            data-testid="student-category-back-btn"
            role="link"
          >
            <ChevronLeftIcon className="ln-icon" />
          </button>
        </ActionHeader>
        <Header>
          <h3>Construction</h3>
          <h6>12 Sub Categories</h6>
        </Header>
        <Content>
          <SubcategoryItem
            title={'Architectural Technologist'}
            description={
              '              The architectural technologist, also known as a building\n' +
              '              technologist, provides technical building design services and is\n' +
              '              trained in architectural technology, building technical design and\n' +
              '              construction. Architectural technologists apply the science of\n' +
              '              architecture and typically concentrate on the technology of\n' +
              '              building, design technology and construction. The training of an\n' +
              '              architectural technologist concentrates on the ever-increasingly\n' +
              '              complex technical aspects in a building project, but matters of\n' +
              '              aesthetics, space, light and circulation are also involved within\n' +
              '              the technical design, leading the professional to assume decisions\n' +
              '              which are also non-technical. They can or may negotiate the\n' +
              '              construction project, and manage the process from conception\n' +
              '              through to completion, typically focusing on the technical aspects\n' +
              '              of a building project.'
            }
            opportunitiesCount={9}
          />
          <SubcategoryItem
            title={'Architectural Technologist'}
            description={
              '              The architectural technologist, also known as a building\n' +
              '              technologist, provides technical building design services and is\n' +
              '              trained in architectural technology, building technical design and\n' +
              '              construction. Architectural technologists apply the science of\n' +
              '              architecture and typically concentrate on the technology of\n' +
              '              building, design technology and construction. The training of an\n' +
              '              architectural technologist concentrates on the ever-increasingly\n' +
              '              complex technical aspects in a building project, but matters of\n' +
              '              aesthetics, space, light and circulation are also involved within\n' +
              '              the technical design, leading the professional to assume decisions\n' +
              '              which are also non-technical. They can or may negotiate the\n' +
              '              construction project, and manage the process from conception\n' +
              '              through to completion, typically focusing on the technical aspects\n' +
              '              of a building project.'
            }
            opportunitiesCount={9}
          />
        </Content>
      </Wrapper>
    );
  }
}
