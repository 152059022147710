import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { MAX_WIDTH_PIXELS } from '../../settings';

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  max-height: 100vh;
  max-height: -webkit-fill-available;
  width: 100%;
  position: relative;
  background: white;
`;

const IntroHeader = styled.div`
  width: 100%;
  max-width: ${MAX_WIDTH_PIXELS}px;
  padding: 1.5rem 0;
  text-align: center;
  position: relative;
  h6 {
    font-size: 1rem;
  }
`;

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  position: absolute;
  top: 1.3125rem;
  left: 2rem;
  font-size: 1rem;
  width: 100px;
  &:hover {
    color: #000000;
  }
  text-align: left;
`;

const ContentWrapper = styled.div`
  padding: 0 2rem;
`;

const IntroTitle = styled.h1`
  font-size: 2rem;
  margin: 1rem 0 0;
  padding: 0;
  line-height: 2.6875rem;
`;

const IntroText = styled.p`
  margin: 1.25rem 0 0;
  font-size: 1rem;
`;

const ButtonContainer = styled.div`
  width: 100%;
  padding: 0 2rem;
  position: absolute;
  bottom: 1rem;
  align-items: flex-end;
  flex-direction: column;
  display: flex;
`;

const Button = styled(Link)`
  height: 55px;
  padding: 0 2rem;
  border-radius: 12px;
  color: #ffffff;
  background-color: #1a1a1a;
  border: none;
  margin-top: auto;
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: bold;
  &:hover {
    color: #ffffff;
    text-decoration: none;
  }
`;

const StepNumber = styled.div`
  margin-top: 2rem;
  font-size: 1rem;
  font-weight: bold;
  color: rgb(255, 195, 78);
`;
const StepHeader = styled.div`
  margin-top: 0.25rem;
  font-size: 2rem;
  line-heihgt: 2.25rem;
  font-weight: bold;
`;
const StepText = styled.div`
  margin-top: -0.25rem;
  font-size: 1rem;
`;

const StudentAssessmentIntro2 = (props) => {
  return (
    <PageWrapper>
      <IntroHeader>
        <BackLink to="/assessment-intro-1" role="link" aria-label="Back">
          <FontAwesomeIcon icon={faChevronLeft} />
        </BackLink>
        <h6>Assessment Survey</h6>
      </IntroHeader>
      <ContentWrapper>
        <IntroText>
          You will be swiping through cards with statements that best describe
          you, from the following categories
        </IntroText>
        <StepNumber>Step 1</StepNumber>
        <StepHeader>Activities</StepHeader>
        <StepText>That you enjoy</StepText>
        <StepNumber>Step 2</StepNumber>
        <StepHeader>Personal Qualities</StepHeader>
        <StepText>That describe you</StepText>
        <StepNumber>Step 3</StepNumber>
        <StepHeader>School Subjects</StepHeader>
        <StepText>That you like</StepText>
      </ContentWrapper>
      <ButtonContainer>
        <Button to="/assessment-intro-3" role="link" aria-label="Continue" data-testid='assessment-intro-3-submit'>
          <FontAwesomeIcon icon={faChevronRight} />
        </Button>
      </ButtonContainer>
    </PageWrapper>
  );
};

export default StudentAssessmentIntro2;
