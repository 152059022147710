import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { API } from '../settings';
import { connect } from 'react-redux';
import { updateUser } from '../features/users/userSlice';

const PageWrapper = styled.div`
  padding: 13px 25px 25px;
`;

const Title = styled.h6`
  text-align: center;
`;

const Check = styled.label`
  display: flex;
  align-items: center;
  margin: 0px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 800;
  justify-content: center;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Input = styled.input`
  margin-left: 10px;
  height: 12px;
`;

const Privacy = ({ user, updateUser }) => {

    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search)
    const enabled = query.get('enabled');

    const [loading, setLoading] = useState()
    
    const handleSubmit = async () => {
        try {
            await API.put(`/users/${user.uuid}/accept-terms`, { accepted_privacy: true });
            updateUser({ accepted_privacy: true });
            history.replace('/accept-terms-privacy');
        } catch (err) {
            setLoading(false)
        }
    }

    const onChange = () => {
        if (loading) return;
        setLoading(true)
        setTimeout(handleSubmit, 200)
    }

    return (
    <PageWrapper>
        <Title>Launch Now</Title>
        <Title>Privacy Policy</Title>
        <p><i>Last Revised: May 24, 2022</i></p> 
        <p>This Launch Now Privacy Policy (“Privacy Policy”) explains how Launch Now, LLC (“Launch Now”, “we,” “us,” or “our”) collects, uses and shares information about you. This Privacy Policy applies when you use the Launch Now website, launchnow.org (the “Site”) or any of its web applications (collectively with the Site, the “Launch Now Platform” or “Platform”), including any written, electronic, and oral communications.</p>
        <p>Please read this Privacy Policy carefully. When you download, install or use the Launch Now Platform, you acknowledge that you have read, understood, and agree to be bound by the terms of this Privacy Policy and consent to Launch Now’s collection and use of personally identifiable information (“Personal Information”) and other information as set forth herein.<b> If you do not consent to the terms of this Privacy Policy, you should not access or use the Launch Now Platform in any way and should immediately exit the Platform.</b></p>
        <h6>Collection of Information</h6>
        <p><b>Collection of Information from Minors:</b> Before a student under the age of 18 is allowed to use or access the Platform, we will seek the consent of the student’s parent or legal guardian via email. This consent may be provided by the parent or guardian online or the parent or guardian may provide a hard copy consent to the student’s school. If a hard copy consent is provided, the school administrator will provide online consent on the parent’s or guardian’s behalf. </p>
        <p>In the email requesting consent, we will explain what information we are collecting, how we plan to use it, how the parent or guardian can provide consent, and how the parent or guardian can revoke consent. If we do not receive consent within a reasonable time, we will delete the parent contact information and any information regarding the student.</p>
        <p>Personal Information:</p>
        <p><b>Parents and Legal Guardians:</b> Personal Information that we collect may include your first and last name, email address (which will become your Platform id), password. and information regarding your children, as described below.</p>
        <p><b>Students:</b> Personal Information that we collect may include information that you provide when using the Platform or information about you that is provided by others, such as a school administrator, and information generated by the Platform. This information may include your</p>
        <ul>
          <li>first name,</li>
          <li>last name,</li>
          <li>date of birth,</li>
          <li>grade,</li>
          <li>email address, (which will become your Platform id),</li>
          <li>password,</li>
          <li>answers that you provide to Launch Now self-assessments including but not limited to questions about your school and extracurricular interests and other personal characteristics,</li>
          <li>individualized career options,</li>
          <li>customized local opportunities,</li>
          <li>community partner connections, and</li>
          <li>social and professional resumes</li>
        </ul>
        <p><b>School Administrators:</b> In order to access or use the Platform you will be required to provide an email address (which will become your Platform id), password, first and last name, school name, and address.</p>
        <p><b>Community Partners</b> In order to access or use the Platform you will be required to provide an email address (which will become your Platform id), password, business address, business category, and other information regarding the business including available student opportunities such as internships and job shadowing. We process payments through Stripe, and in order to use the Platform, you will be required to provide credit card or banking information directly to Stripe. Launch Now will not have access to this payment information.</p>
        <p>Under no circumstances should you provide your social security number, driver’s license number, or other sensitive Personal Information through the Platform.</p>
        <p><b>Use Data:</b> By choosing to use the Platform, you agree to provide Launch Now with certain information that is automatically created, collected, and recorded by your use of the Platform (“Use Data”). Launch Now may use third-party software tools (the “Tools”), such as “cookies” and analysis software to gather Use Data. This may include but is not limited to your device type, device’s IP address, unique device IDs, internet service provider, browser type, browser version, the pages or products you view, the time spent on those pages, referring pages and search terms, date and time stamps, and other site visitation data. Use Data is collected on an aggregate basis.</p>
        <h6>Use of Information</h6>
        <p>Launch Now uses your information, including Personal Information, for various purposes in connection with its business, including, but not limited to, providing and managing services in connection with the Platform, including providing students with tailored career options and customized local opportunities, providing a platform to store students’ social and professional resumes, and connecting students with community partners, providing customer service and support, responding to your inquiries and requests, providing you with information related to the Platform or that we otherwise believe will be of interest to you, and notifying you about certain changes to the Platform.</p>
        <p>Launch Now uses Use Data in order to monitor use of the Platform and our services, to help us understand how people use the Platform, for the Platform’s technical administration, to improve the Platform and our services, We may also use aggregated or anonymized information that does not directly identify you for marketing, advertising, or other uses.</p>
        <h6>E-mail Correspondence</h6>
        <p>If you create an account on the Platform, you authorize us to send you regular emails unless and until you request to unsubscribe from such emails. We may use your email to send transactional emails, to respond to your inquiries, requests or questions, to provide information about changes to the Platform, and to provide other information that we believe may be of interest to you. Each email we send contains an easy, automated way for you to unsubscribe from further emails from us. Select the "unsubscribe" link in the footer of an email from us and follow the directions to communicate your preferences to us.</p>
        <h6>Sharing of Information</h6>
        <p><b>Students:</b> Your Personal Information may be shared with your school administrator and parents or legal guardians. You may choose to share your Personal Information with a community partner after you request a connection to that community partner and your school administrator has authorized that connection.</p>
        <p><b>School Administrators:</b> We may share your Personal Information with students, parents, legal guardians, and community partners.</p>
        <p><b>Community Partners:</b> We may share your information with students, parents, legal guardians, and school administrators.</p>
        <p><b>Service Providers:</b> We engage certain trusted third parties to perform functions and provide services to us, including, without limitation, hosting and maintenance, customer relations, database storage and management, data analysis, and legal, accounting, and/or marketing services. We may share your Personal Information or Use Data with these third parties, but only to the extent necessary to perform these functions and provide such services, and only pursuant to binding contractual obligations requiring third parties to maintain the privacy and security of your data.</p>
        <p><b>Other Third Parties:</b> We also may share, disclose, or transfer your Personal Information or Use Data with third parties as follows: (a) to government agencies, advisors and other third parties, where necessary and appropriate, in order to comply with applicable laws, the service of legal process, or if we reasonably believe that such action is necessary to (i) comply with the law requiring such disclosure, (ii) protect Launch Now’s rights or property, (iii) prevent a crime or protect national security or (iv) protect the personal safety of the users or the public; (b) if we believe your actions violate our Terms of Service or other policies; or (c) in connection with a financing or a sale, acquisition or merger of our business or our business assets. We may also share with others aggregated or anonymized information that does not directly identify you.</p>
        <p><b>Your Rights Regarding Personal Information:</b> Parents, legal guardians and eligible students may contact the school district in order to review and correct a student’s personal information on the pupil’s records, including information held by Launch Now, and may also request the transfer of pupil-generated content to a personal account.</p>
        <p>A parent or legal guardian of a student under the age of 13 may request that the student’s Personal Information be deleted and may also refuse to permit the further collection or use of the student’s Personal Information. The parent or legal guardian should contact the school district in order to make these requests.</p>
        <h6>Third Party Sites or Apps</h6>
        <p>The Platform may contain links to external websites, plug-ins or applications. Please note that this Privacy Policy applies only to the Platform, and not to third party websites or applications to which the Platform links. Launch Now is not responsible for the practices and policies (including privacy policies) of third parties and, therefore, you should review the privacy practices and policies of such third parties prior to providing your Personal Information in connection with such websites or applications.</p>
        <h6>DO NOT TRACK</h6>
        <p>We do not track your location when using the Platform.</p>
        <h6>Security</h6>
        <p>Launch Now uses reasonable care to protect the privacy and security of Personal Information and to ensure that our systems are secure and meet industry standards. Where appropriate, we employ firewalls, encryption technology, and user authentication systems (e.g., usernames and passwords) to control access to systems and data.</p>
        <p>Unfortunately, no Internet transmission is ever completely secure, and we cannot guarantee that unauthorized access, hacking, data loss or other breaches will never occur. WE EXPRESSLY DISCLAIM ANY REPRESENTATION OR WARRANTY, WHETHER EXPRESS OR IMPLIED, WITH RESPECT TO ANY BREACHES OF SECURITY, DAMAGE TO YOUR DEVICE, OR ANY LOSS OR UNAUTHORIZED USE OF YOUR PERSONAL INFORMATION OR OTHER DATA.</p>
        <h6>Unauthorized Use</h6>
        <p>Unauthorized attempts to use the Platform in a way that violates our Terms of Service, to utilize the Platform for other than its intended purposes, or to defeat or circumvent the Platform’s security features are strictly prohibited.</p>
        <h6>Privacy Policy Updates</h6>
        <p>Launch Now may amend, update or modify the terms of this Privacy Policy at any time, by posting an amended Privacy Policy on the Site or the Platform. If you do not consent to the terms of this Privacy Policy, as amended, you should not access or use the Platform in any way and should immediately remove or uninstall the Platform. By continuing to access or use the Platform after those changes become effective, you agree to be bound by the revised Privacy Policy.</p>
        <h6>Contact Us</h6>
        <p>Launch Now, LLC, the operator of the Launch Now Platform, is located at 20 Portsmouth Ave, Unit 1, Stratham, NH 03885, phone number 800-948-1120. If you have questions or comments about this Privacy Policy, please contact us at info@launchnow.org</p>
        {enabled && <Check>
            I accept these terms
            <Input onChange={onChange} type='checkbox' 
              data-testid="privacyCheckbox"/>
        </Check>}
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = { updateUser };

export default connect(mapStateToProps, mapDispatchToProps)(Privacy);

