import React from 'react';
import styled from 'styled-components';

const Title = styled.h4`
  font-size: 15px;
  margin-bottom: 30px;
`;

const Header = styled.div`
  display: flex;
  background: rgba(0,0,0,0.020);
  margin-bottom: 15px;
  border-radius: 10px;
`;

const Body = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
`;

const ColTitle = styled.div`
  font-size: 11px;
  text-transform: uppercase;
  font-weight: 800;
  padding: 7px 3px;
  margin-right: 5px;
  align-self: flex-start;
`;

const Data = styled.div`
  font-size: 13px;
  background: rgba(0,0,0,0.020);
  align-self: flex-start;
  padding: 3px 7px;
  border-radius: 10px;
  margin-bottom: 13px;
`;

const MoreButton = styled.div`
  font-size: 10px;
  background: rgba(0,0,0,0.020);
  align-self: flex-start;
  padding: 5.5px 7px;
  border-radius: 10px;
  margin-bottom: 13px;
  font-weight: 800;
  align-self: flex-end;
  cursor: pointer;
`;

const StudentTable = ({
  style, 
  columns
}) => {

    if (!columns) return <div />
  

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '1000px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        overflowY: 'scroll',
        ...style,
      }}
    >
      <Header>{columns?.map((c) => 
        <ColTitle style={{ flex: c.span }}>{c.title}</ColTitle>)}
      </Header>
      <Body>
      {columns?.map(
        (c) => <Column style={{ flex: c.span }}>
        { 
          c.data.map((d, i) => 
          c.onClick ?
          <MoreButton onClick={() => c.onClick(i)}>MORE &gt;</MoreButton> :
          c.component ? 
          <c.component>{c.formatter ? c.formatter(d) : d}</c.component> :
          <Data>{c.formatter ? c.formatter(d) : d}</Data>)
        }
      </Column>)}
      </Body>

    </div>
  );
};

export default StudentTable;
