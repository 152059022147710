import dotenv from 'dotenv';
import axios from 'axios';

dotenv.config();

export const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

export const openApiBaseUrl = process.env.REACT_APP_OPEN_API_BASE_URL;

export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;

export const sentryTracesSampleRate = parseFloat(
  process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE
);

export const MAX_WIDTH_PIXELS = 576;

export const algolia = {
  appId: process.env.REACT_APP_ALGOLIA_APP_ID,
  searchApiKey: process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY,
  indexName: process.env.REACT_APP_ALGOLIA_INDEX_NAME,
};

export const API = axios.create({
  baseURL: apiBaseUrl,
  withCredentials: true,
});

export const OPEN_API = axios.create({
  baseURL: openApiBaseUrl,
  withCredentials: true,
});

API.interceptors.response.use(
  response => response,
  (error) => {
    const { response, config } = error;
    if (
      response?.status === 401 
      && response?.data?.message === 'Renew Token'
    ) {
      return API.request(config);
    } else if (response?.status === 403) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = '/';
    }
    return Promise.reject(error);
  }
);


