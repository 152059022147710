import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import arrowDown from '../../images/arrow-down.svg';
import useCollapsible from '../shared/useCollapsible';

const Title = styled.div`
    font-size: 13.5px;
    font-weight: 800;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
        height: 7px;
        transform: ${props => props.childrenHidden ? 'rotate(180deg)' : 'rotate(0deg)'}
    }
    
`;

const Check = styled.input`
    height: min-content;
    width: min-content;
    appearance: auto !important;
    background: transparent;
    margin-right: 10px;
    border: none;
    background-color: transparent;
    outline: none;
    height: 10px;

`;


const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid rgba(0,0,0,0.1);
    padding-bottom: 5px;
    margin-bottom: 10px;
`;

const OptionsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 13px;
`;

const OptionWrapper = styled.label`
    display: flex;
    margin-bottom: 5px;
    align-items: center;
`;

const Option = ({
    value,
    title,
    parentValue,
    toggleFilter,
    count
}) => {


    const onClickHandler = () => {
        toggleFilter({ 
            value,
            parentValue,
            title
        })
    }

    return <OptionWrapper>
        <Check onClick={onClickHandler} type='checkbox' />
        {title}
        <div style={{ flex: 1 }} />
        {count !== undefined && <div style={{ opacity: 0.5}}>({count})</div>}
    </OptionWrapper>
}



export default function FilterDropdown({
    title,
    value,
    options,
    toggleFilter,
}) {


    const [filtersVisible, setFiltersVisible] = useState(true);
    const { ref, style } = useCollapsible(filtersVisible);
    const toggleVisible = () => {
        setFiltersVisible((v) => !v)
    };

    return <Wrapper>
        <Title childrenHidden={!filtersVisible} onClick={toggleVisible}>{title}
            <img alt='arrow down' src={arrowDown} />
        </Title>
        <OptionsWrapper ref={ref} style={style}>
            {options.map(op => <Option parentValue={value} toggleFilter={toggleFilter} {...op} />)}
        </OptionsWrapper>
    </Wrapper>

}