import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { clearToast } from '../../features/users/userSlice';
import { counselorGetReporting } from '../../redux/actions/counselor';
import BarChartLN from './DashboardStatCards/BarChartLN';
import Table from './DashboardStatCards/Table';
import StudentTable from './DashboardStatCards/StudentTable';
import moment from 'moment';
import closeBtn from '../../images/close.svg';
import { useHistory } from 'react-router-dom';
import FilterDropdown from './FilterDropdown';
import { Spinner } from 'react-bootstrap';

const Row = styled.div`
  display: flex;
  border-top: 1px solid rgba(0,0,0,0.1);
`;

const SpinnerIcon = styled(Spinner)`
  height: 30px;
  width: 30px;
`;

const FilterColumn = styled.div`
  display: flex;
  flex-direction: column;
  background: rgba(0,0,0,0.05);
  width: 250px;
  padding: 20px;
  box-sizing: border-box;
`;

const FilterProps = [
  {
    title: 'Student Grade',
    value: 'grade',
    options: [
      { title: '6th grade', value: 6, count: 0 },
      { title: '7th grade', value: 7, count: 0 },
      { title: '8th grade', value: 8, count: 0},
      { title: '9th grade', value: 9, count: 0 },
      { title: '10th grade', value: 10, count: 0 },
      { title: '11th grade', value: 11, count: 0 },
      { title: '12th grade', value: 12, count: 0},
      { title: '12+ grade', value: 13, count: 0 },
    ]
  },
  /*{
    title: 'Student Gender',
    value: 'gender',
    options: [
      { title: 'Female', value: 'female' },
      { title: 'Male', value: 'male' },
      { title: 'Other', value: 'other' },
    ]
  },*/
  {
    title: 'Opportunity Type',
    value: 'opportunityType',
    options: [
      { title: 'On-site', value: 'ONSITE' },
      { title: 'Remote', value: 'REMOTE' },
    ]
  },
  {
    title: 'Employment Type',
    value: 'employmentType',
    options: [
      {
        title: 'Full-Time',
        value: 'Full-Time',
      },
      {
        title: 'Part-Time',
        value: 'Part-Time',
      },
      {
        title: 'Temporary',
        value: 'Temporary',
      },
      {
        title: 'Seasonal',
        value: 'Seasonal',
      },
      {
        title: 'Job Shadow',
        value: 'Job Shadow',
      },
      {
        title: 'Virtual Meet & Greet',
        value: 'Virtual Meet & Greet',
      },
      {
        title: 'ELO',
        value: 'ELO',
      },
    ]
  },
  /*
  {
    title: 'Opportunity Status',
    value: 'status',
    options: [
      { title: 'Interested In', value: 'InterestedIn' },
      { title: 'Completed', value: 'Completed' },
    ]
  }
  */
]

const DataColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-right: 100px;
  padding: 20px;
  box-sizing: border-box;
`;

const keyFetcher = (data, key, formatter = (v) => v) => {
  return data?.map((d) => formatter(key?.reduce((p, n, i) => { 
    if (!d[n]) return undefined;
    if (i > 0) p = p + ' ';
    p = p + d[n]; 
    return p 
  }, ''))) || []
}


const Wrapper = styled.div`
  padding: 0px;
  position: relative;
`;

const Header = styled.div`
  margin-top: 5px;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
`;

const Modal = styled.div`
  background: white;
  height: 60vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 70vw;
  min-height: 600px;
  border-radius: 20px;
  padding: 30px;
  padding-top: 25px;
`;

const LoadingWrapper = styled.div`
  position: absolute;
  top: -5px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  background: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  padding-top: 300px;
  z-index: 1000;
`;

const CloseButton = styled.img`
  cursor: pointer;
  height: 30px;
  width: 30px;
`;

const Filters = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
  flex-wrap: wrap;
`;

const Filter = styled.div`
  border-radius: 100px;
  border: 1px solid gray;
  background: rgba(0,0,0,0.1);
  margin-right: 5px;
  font-size: 10px;
  padding: 5px 10px;
  margin-bottom: 8px;
`;  

const StudentList = ({data, visible, title, close}) => {

  const history = useHistory();

  if (!visible) {
    return <div />
  }

  const closeModal = (e) => {
    if (e.target !== e.currentTarget) return;
    close();
  }

  const columns = [
    {
      title: 'Student Name',
      span: 3,
      data: keyFetcher(data, ['User.firstName', 'User.lastName'])
    },
    {
      title: 'Date of Birth',
      span: 3,
      data: keyFetcher(data, ['dob'], (v) => v ? moment(v).format('MM/DD/YYYY') : 'N/A') || []
    },
    {
      title: 'Email Address',
      span: 3,
      data: keyFetcher(data, ['User.email']) || [],
    },
    {
      title: '',
      span: 1,
      data: Array.from(Array(data?.length)),
      onClick: (i) => {
        const id = keyFetcher(data, ['User.uuid'])[i]
        history.push('/counselor/students/' + id)
      }
    }
  ];

  return <Mask onClick={closeModal}>
    <Modal>
      <Header>
        <h4>{title}</h4>
        <CloseButton src={closeBtn} onClick={close} />
      </Header>
      <StudentTable columns={columns} />
    </Modal>
  </Mask>

}


const Reporting = (props) => {
  const { clearToast, user, reports } = props;
  const { toastMessage, toastStatus } = user;
  const [defaultFilters, setDefaultFilters] = useState(FilterProps)
  const [ filters, setFilters ] = useState([]);
  const [ loading, setLoading ] = useState(true);
  const [data, setData] = useState([]);
  const [modalTitle, setModalTitle] = useState('');

  // Todo: abstract this to a commponent
  useEffect(() => {
    if (toastMessage && toastStatus) {
      if (toastStatus === 'success') {
        toast.success(toastMessage);
      } else if (toastStatus === 'info') {
        toast.info(toastMessage);
      }
      clearToast();
    }
  });

  const getReporting = async () => {
    setLoading(true);
    await props.counselorGetReporting(filters);
    setLoading(false);
  }

  const assignStudentCount = () => {
    if (props?.reports?.unfilteredStudentsGroupedByGrade) {
      
      const s = props.reports.unfilteredStudentsGroupedByGrade;
      setDefaultFilters((f) => {
        return f.map((fi) => {
          if (fi.title !== 'Student Grade') return fi;
          fi.options = fi.options.map((op) => (
            {...op, count: s.find((si) => si.grade === op.value)?.count || 0}
          ))
          return fi;
        })
      })
    }
  }

  useEffect(() => {
    getReporting()
  }, [filters]);

  useEffect(() => {
    assignStudentCount();
  }, [props.reports])

  const toggleFilter = (filter) => {
    setFilters((existing) => {      
      const newExisting = existing.filter((f) => `${f.value}.${f.parentValue}` !== `${filter.value}.${filter.parentValue}`) 
      if (newExisting.length !== existing.length) return newExisting;
      else return [...existing, filter];
    })
  }
  
  const topMatchingClusters = props?.reports?.clustersByGrade?.map((c) => {
    if (!c.clusterId) return c;
    else return {...c, cluster: props.reports?.clusterData[c.clusterId]?.name}
  });

  const topSavedSubcategories = props?.reports?.savedByGrade?.map((c) => {
    if (!c.subcategoryId) return c;
    else return {...c, subcategory: props.reports?.subcategoryData[c.subcategoryId]?.name}
  });

  const topSavedOpportunities = props?.reports?.topSavedOpportunities?.map((c) => {
    if (!c.opportunityId) return c;
    else return {...c, ...props.reports?.opportunityData[c.opportunityId]}
  });
  
  const topMatchingClustersColumns = [
    {
      title: 'Grade',
      span: 1,
      data: keyFetcher(topMatchingClusters, ['grade'], (v) => v === 13 ? '12+' : v.toString() + 'th') 
    },
    {
      title: 'Category',
      span: 3,
      data: keyFetcher(topMatchingClusters, ['cluster'], (v) => v || 'N/A')
    },
    {
      title: 'Students Matching',
      span: 3,
      data: keyFetcher(topMatchingClusters, ['percent'], (v) => v ? Math.ceil(v * 100) + '%' : '0%' )
    },
    {
      title: 'No of Students',
      span: 3,
      data: keyFetcher(topMatchingClusters, ['number'], (v) => v || 0 )
    },
    {
      title: '',
      span: 1,
      data: Array.from(Array(topMatchingClusters?.length)),
      onClick: (i) => {
        const key = keyFetcher(topMatchingClusters, ['grade'])[i];
        const cluster = keyFetcher(topMatchingClusters, ['cluster'])[i];
        const numberKey = parseInt(key);
        if (!props?.reports?.clusterStudents[numberKey].length) return toast.error('No students found');
        setModalTitle(cluster);
        setData(props?.reports?.clusterStudents[numberKey])
      }
    }
  ];
  
  const topSavedSubcategoriesColumns = [
    {
      title: 'Grade',
      span: 1,
      data: keyFetcher(topSavedSubcategories, ['grade'], (v) => v === 13 ? '12+' : v.toString() + 'th') 
    },
    {
      title: 'Subcategory',
      span: 3,
      data: keyFetcher(topSavedSubcategories, ['subcategory'], (v) => v || 'N/A')
    },
    {
      title: 'No of Students',
      span: 5,
      data: keyFetcher(topSavedSubcategories, ['number'], (v) => v || 0)
    },
    {
      title: '',
      span: 1,
      data: Array.from(Array(topSavedSubcategories?.length)),
      onClick: (i) => {
        const key = keyFetcher(topSavedSubcategories, ['grade'])[i]
        const numberKey = parseInt(key)
        const subcategory = keyFetcher(topSavedSubcategories, ['subcategory'])[i]
        if (!props?.reports?.savedSubcategoryStudents[numberKey]?.length) return toast.error('No students found');
        setModalTitle(subcategory)
        setData(props?.reports?.savedSubcategoryStudents[numberKey])
      }
    },
    
  ];

  const topSavedOpportunityColumns = [
    {
      title: 'Title',
      span: 5,
      data: keyFetcher(topSavedOpportunities, ['title']) 
    },
    {
      title: 'Type',
      span: 1,
      data: keyFetcher(topSavedOpportunities, ['performedAt'], (v) => v || 'N/A')
    },
    {
      title: 'Location',
      span: 1,
      data: keyFetcher(topSavedOpportunities, ['number'], (v) => v || 'None')
    },
    {
      title: 'Saved By',
      span: 1,
      data: keyFetcher(topSavedOpportunities, ['number'], (v) => v || 0)
    },
    {
      title: '',
      span: 1,
      data: Array.from(Array(topSavedOpportunities?.length)),
      onClick: (i) => {
        const title = keyFetcher(topSavedOpportunities, ['title'])[i]
        if (!props?.reports?.savedOpportunityStudents[i]?.length) return toast.error('No students found');
        setModalTitle(title)
        setData(props?.reports?.savedOpportunityStudents[i])
      }
    }
  ];

  

  return (

      <Wrapper>
        {loading && <LoadingWrapper>
          <SpinnerIcon animation='border' variant='secondary' />
        </LoadingWrapper>}
        <StudentList 
          visible={data?.length > 0} 
          close={() => setData([])} 
          title={modalTitle}
          data={data}
        />
        <Header>
          <h3>Reports</h3>
        </Header>
        <Row>
          <FilterColumn>
            {defaultFilters.map((propGroup) => <FilterDropdown toggleFilter={toggleFilter} {...propGroup} />) }
          </FilterColumn>
          <DataColumn>
            <Filters>
              {filters.map(({ value, title }) => <Filter>{title}</Filter>)}
            </Filters>
            <BarChartLN 
              style={{ marginBottom: 20 }}
              title='Assessment Completion'
              tags={['Onboarded Students']}
              data={reports?.onboardingByGradeStatus} 
              loading={props.loading} />
            <BarChartLN 
              title='Opportunity Status' 
              multipleBars={true} 
              style={{ marginBottom: 20 }}
              data={reports?.interestedByGrade} 
              formatter={(d) => (d)} 
              yAxisFormatter={(v) => `${v}`}
              domain={[Math.min(reports?.interestedByGrade?.map((v) => Math.min(v.completed, v.interested))),
                Math.max(reports?.interestedByGrade?.map((v) => Math.max(v.completed, v.interested)))]}
              keys={['interested', 'completed']}
              tags={['Interested', 'Completed']}
              singleValueFormatter={(v) => v }
              loading={props.loading} />
            <Table
              title='Top Matching Categories'
              style={{ marginBottom: 20 }}
              columns={topMatchingClustersColumns}
            />
            <Table
              title='Top Saved Subcategories'
              style={{ marginBottom: 20 }}
              columns={topSavedSubcategoriesColumns}
            />
            <Table
              title='Top Saved Opportunities'
              style={{ marginBottom: 20 }}
              columns={topSavedOpportunityColumns}
            />
          </DataColumn>
      </Row>
      </Wrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  reports: state.counselor.dashboard.reports,
});

const mapDispatchToProps = { clearToast, counselorGetReporting };

export default connect(mapStateToProps, mapDispatchToProps)(Reporting);
