import React, { Component } from 'react';
import styled from 'styled-components';
import {
  Bookmark28Filled as BookmarkedIcon,
  Bookmark28Regular as BookmarkIcon,
  ChevronLeft28Regular as ChevronLeftIcon,
} from '@fluentui/react-icons';
import { withRouter } from 'react-router-dom';
import { API } from '../../settings';
import { Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import InterestedInBtn from './StudentInterestedIn/InterestedInBtn';
import {
  getStudentInterested,
  getStudentSaved,
} from '../../redux/actions/student';
import { SAVE_OPPORTUNITY, track, UNSAVE_OPPORTUNITY } from '../../utils/mixpanel';

const Wrapper = styled.div`
  padding: 20px;
  max-height: calc(100vh - 60px - 70px);
  overflow-y: auto;
  max-width: 576px;
  margin: 0 auto;
`;

const ActionHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  max-width: 576px;
  margin: 0 auto;
`;

const BackBtn = styled.button`
  justify-self: flex-start;
`;

const PageTitle = styled.h6`
  width: 100%;
  text-align: center;
  margin-right: 40px;
  margin-bottom: 0;
`;

const Description = styled.div`
  .title {
    font-size: 16px;
    margin-bottom: 1rem;
  }

  p {
    font-size: 14px;
  }
`;

const Skill = styled.div`
  background: #f2f3f5;
  padding: 10px;
  display: inline-block;
  border-radius: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  max-width: 576px;
  margin: 0 auto;
`;

const BookmarkBtn = styled.button`
  background: #f2f3f5;
  color: #afafaf;
  height: 50px;
  width: 50px;
  min-width: 50px;
  justify-content: center;
  padding: 0;

  &.bookmarked {
    background-color: #ffc34e;

    svg {
      color: #fff;
    }
  }
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

class StudentOpportunity extends Component {
  state = {
    opportunity: {},
    loading: true,
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    API.get(`/opportunities/${this.props.match.params.opportunityId}`).then(
      (res) => {
        this.setState({ opportunity: res.data, loading: false });
      }
    );
  };

  bookmark = (id, status) => {
    let { opportunity } = this.state;
    opportunity.isBookmarked = status;
    this.setState({ opportunity });
    const {
      user: { uuid },
    } = this.props;
    API({
      url: `/opportunities/saved/${id}?uuid=${uuid}`,
      method: status ? 'POST' : 'DELETE',
    }).then(() => {
      track(status ? SAVE_OPPORTUNITY : UNSAVE_OPPORTUNITY);
      toast.success(`Opportunity ${status ? 'saved' : 'removed'}`, {
        style: {
          background: status ? '#ffc34e' : '#333333',
        },
      });
      this.props.getStudentSaved(1);
    });
  };

  onInterest = (isInterestedIn) => {
    let { opportunity } = this.state;
    opportunity.isInterestedIn = !opportunity.isInterestedIn;
    this.setState({ opportunity });
    this.props.getStudentInterested(1);
  };

  render() {
    const { loading, opportunity } = this.state;

    if (loading && !Object.keys(opportunity).length) {
      return (
        <center>
          <LoadingSpinner animation="border" />
        </center>
      );
    } else {
      const {
        id,
        title,
        description,
        Cluster,
        Employer,
        location,
        State,
        employmentType,
        performedAt,
        isBookmarked,
        isInterestedIn,
        companyName,
        approvedByCounselor,
        approvedByEmployer,
        finished
      } = opportunity;
      return (
        <>
          <ActionHeader>
            <BackBtn
              onClick={this.props.history.goBack}
              className="ln-btn ln-icon-btn basic"
              data-testid="student-opportunity-back-btn"
              role="link"
            >
              <ChevronLeftIcon className="ln-icon" />
            </BackBtn>
            <PageTitle>{Employer.name}</PageTitle>
          </ActionHeader>
          <Wrapper>
            <CompanyHeader
              companyName={Employer.name}
              // logo='https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/180px-Google_%22G%22_Logo.svg.png'
              opportunityTitle={title}
              location={location && State ? `${location}, ${State.code}` : ''}
              employmentType={employmentType}
              performedAt={performedAt}
            />
            <Description>
              <section className="mg-b">
                <h6 className="title">About the Opportunity</h6>
                <p>{description}</p>
              </section>
              <section>
                <h6 className="title">Skills</h6>
                {Array.isArray(Cluster.Skills)
                  ? Cluster.Skills.map((skill, i) => (
                      <Skill key={skill.id}>{skill.name}</Skill>
                    ))
                  : null}
              </section>
            </Description>
            {/*<Tabs*/}
            {/*  items={[*/}
            {/*    <Tab title="Description">*/}
            {/*      <Description>*/}
            {/*        <section className="mg-b">*/}
            {/*          <h6 className="title">About the Opportunity</h6>*/}
            {/*          <p>{description}</p>*/}
            {/*        </section>*/}
            {/*        <section>*/}
            {/*          <h6 className="title">Skills</h6>*/}
            {/*          {Array.isArray(Cluster.Skills)*/}
            {/*            ? Cluster.Skills.map((skill, i) => (*/}
            {/*                <Skill key={skill.id}>{skill.name}</Skill>*/}
            {/*              ))*/}
            {/*            : null}*/}
            {/*        </section>*/}
            {/*      </Description>*/}
            {/*    </Tab>,*/}
            {/*    <Tab title='Company'>*/}
            {/*      <Description>*/}
            {/*        <section className='mg-b'>*/}
            {/*          <h6 className='title'>About {companyName}</h6>*/}
            {/*          <p>{description}</p>*/}
            {/*        </section>*/}
            {/*      </Description>*/}
            {/*    </Tab>*/}
            {/*  ]}*/}
            {/*/>*/}
          </Wrapper>
          <Footer>
            {opportunity.available > 0 && <BookmarkBtn
              onClick={() => this.bookmark(id, !isBookmarked)}
              className={`ln-btn ${isBookmarked ? 'bookmarked' : ''}`}
              data-testid="student-opportunity-bookmark-btn"
            >
              {isBookmarked ? <BookmarkedIcon /> : <BookmarkIcon />}
            </BookmarkBtn>}
            <InterestedInBtn
              isInterestedIn={isInterestedIn}
              opportunityId={id}
              onChange={this.onInterest}
              className="mg-l"
              title={title}
              opportunity={opportunity}
              location={location}
            />
          </Footer>
        </>
      );
    }
  }
}

export default connect(
  (state) => ({
    user: state.user,
  }),
  { getStudentInterested, getStudentSaved }
)(withRouter(StudentOpportunity));

const CompanyHeaderWrapper = styled.div`
  text-align: center;
`;

const CompanyLogo = styled.img`
  height: 45px;
  width: 45px;
  margin: 20px;
`;

const OpportunityTitle = styled.h5`
  padding: 10px;
  margin: 0;
`;

const Meta = styled.div`
  color: #afafaf;
  margin-bottom: 10px;
  font-size: 12px;
`;

const Tags = styled.div`
  text-align: center;
  padding: 5px 10px 20px 10px;
`;

const Tag = styled.div`
  background: #f2f3f5;
  color: #afafaf;
  padding: 3px 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  font-size: 12px;
  border-radius: 4px;
`;

const CompanyHeader = (props) => {
  let {
    logo,
    companyName,
    opportunityTitle,
    employmentType,
    performedAt,
    location,
  } = props;
  return (
    <CompanyHeaderWrapper>
      {/*<CompanyLogo src={logo} alt={companyName} />*/}
      <OpportunityTitle>{opportunityTitle}</OpportunityTitle>
      <Meta>
        {companyName} • {location ? `${location} •` : ''} {employmentType}
      </Meta>
      <Tags>
        <Tag>{capitalizeFirstLetter(performedAt)}</Tag>
      </Tags>
    </CompanyHeaderWrapper>
  );
};

function capitalizeFirstLetter(string) {
  string = string.toLowerCase();
  return string.charAt(0).toUpperCase() + string.slice(1);
}
