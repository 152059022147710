export const validateEmail = (email) => {
  if (!email) return false;
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

export const validateXlsDate = (date) => {
  const parts = date.split('/');
  if (parseInt(parts[0]) > 12) return false;
  if (parseInt(parts[1]) > 31) return false;
  let year = parts[2];
  const currentYear = parseInt(new Date().getFullYear().toString().slice(2, 4));
  year = parseInt(year) > currentYear ? '19' + year : '20' + year;
  const JSdate = new Date(year, parts[0], parts[1]);
  return JSdate?.getTime() ? true : false;
};
