import React, { Component } from 'react';
import styled from 'styled-components';
import { API } from '../../../settings';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import MobileSlidePopUp from '../../common/MobileSlidePopUp';
import { Delete28Regular } from '@fluentui/react-icons';
import { track, UNDO_INTERESTED_IN_OPPORTUNITY, INTERESTED_IN_OPPORTUNITY } from '../../../utils/mixpanel';
import SubcategoryPanelSaveButton from '../../SubcategoryPanelSaveButton';
import SaveSubcategoryButton from '../StudentCategory/SaveSubcategoryButton';

const Button = styled.button`
  //margin-left: 1rem;
  height: 50px;
  text-align: center;
  justify-content: center;

  &.default {
    color: #b6b6b6;
  }
`;

const DeleteIcon = styled(Delete28Regular)`
  color: #fe7e7e;
`;

class InterestedInBtn extends Component {
  state = {
    messageActive: false,
  };

  onInterest = () => {
    let {
      isInterestedIn,
      opportunityId,
      user: { uuid },
      onChange,
      title,
      companyName,
      location,
      available,
      iconBtn,
    } = this.props;
    
    isInterestedIn = !isInterestedIn;
    if (opportunityId && onChange) {
      onChange(isInterestedIn);

      track(isInterestedIn ? INTERESTED_IN_OPPORTUNITY : UNDO_INTERESTED_IN_OPPORTUNITY, {       
        title,
        companyName,
        location
      })

      API({
        url: `/opportunities/interested/${opportunityId}?uuid=${uuid}`,
        method: isInterestedIn ? 'POST' : 'DELETE',
      }).then(() => {
        toast.success(
          `${
            isInterestedIn
              ? 'Your guidance counselor will be notified to look into this opportunity for you'
              : 'Interest revoked'
          }`,
          {
            style: {
              background: isInterestedIn ? '#ffc34e' : '#fe7272',
            },
          }
        );
      });
    }
  };

  render() {
    const { isInterestedIn, className, iconBtn, opportunity } = this.props;
    const interestedOpportunity = opportunity?.InterestedOpportunities[0];
    const approvedByCounselor = interestedOpportunity?.approvedByCounselor;
    const approvedByEmployer = interestedOpportunity?.approvedByEmployer;
    const finished = interestedOpportunity?.finished;

    if (finished) {
      return <>
          <Button
            disabled={true}
            onClick={this.onInterest}
            style={{ background: 'black' }}
            className="ln-btn primary full-width mg-l"
          >
            Completed
          </Button>
      </>
    } else if (approvedByEmployer) {
      return <>
          <Button
            disabled={true}
            onClick={this.onInterest}
            style={{ background: 'yellow' }}
            className="ln-btn primary full-width mg-l"
          >
            Matched to Opportunity
          </Button>
      </>
    } else if (approvedByCounselor) {
      return <>
          <Button
            disabled={true}
            onClick={this.onInterest}
            className="ln-btn primary full-width mg-l"
            data-testid="interested-in-btn-approved"
          >
            Approved by Counselor
          </Button>
      </>
    } else if (isInterestedIn) {
      return (
        <MobileSlidePopUp
          title="Revoke Interest?"
          closeButton={false}
          footerCancelBtn={
            <Button className="ln-btn primary full-width">
              No. Still Interested
            </Button>
          }
          footerOKBtn={<Button className="ln-btn danger">Revoke</Button>}
          footer={true}
          onOKClick={this.onInterest}
          firstFooterBtn="OK"
          initiator={
            iconBtn ? (
              <button onClick={this.onInterest} className="ln-btn ln-icon-btn">
                <DeleteIcon />
              </button>
            ) : (
              <Button className={`ln-btn default full-width ${className}`}>
                Not Interested ?
              </Button>
            )
          }
        >
          Are you sure you want to revoke your interest for this opportunity?
        </MobileSlidePopUp>
      );
    } else {
      return (
        <>
          <Button
            onClick={this.onInterest}
            className="ln-btn primary full-width mg-l"
            data-testid="express-interested-opportunity-btn"
          >
            I'm Interested
          </Button>
        </>
      );
    } 
  }
}

export default connect((state) => ({
  user: state.user,
}))(InterestedInBtn);
