import React from 'react';
import { useField, useFormikContext } from 'formik';
import DatePicker from 'react-datepicker';

export const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const { value, onDatePickerChange = () => {} } = props;

  return (
    <DatePicker
      {...field}
      {...props}
      selected={(field.value && new Date(field.value)) || null}
      onChange={(val) => {
        setFieldValue(field.name, val);
        setTimeout(() => onDatePickerChange(val), 200);
      }}
      autoComplete="off"
      className="text-input form-control"
      value={field.value || value}
    />
  );
};

export default DatePickerField;
