import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import {
  PeopleCommunity24Regular as OpportunitiesIcon,
  Settings24Regular as SettingsIcon,
  SlideLayout24Regular as OverviewIcon,
} from '@fluentui/react-icons';
import Avatar from 'react-avatar';
import { connect } from 'react-redux';
import { fadeIn as AnimationType } from 'react-animations';
import { API } from '../../settings';
import { updateUser } from '../../features/users/userSlice';
import getRouteLevels from './getRouteLevels';

const Animation = styled.div`
  animation: 0.5s ${keyframes`${AnimationType}`};
`;

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
`;

const LeftNav = styled.div`
  flex: 0 0 220px;
  height: 100%;
  background-color: #333333;
`;

const FluidContent = styled.div`
  flex: 1 1 auto;
  overflow: auto;
  background: #fbfbfb;
  padding: 20px;
`;

const NavHeading = styled.h2`
  color: #fff;
  font-size: 22px;
  line-height: 22px;
  margin: 31px 0 0 18px;
`;

const HighlightedText = styled.span`
  background: linear-gradient(to top, #ffc34e 50%, transparent 50%);
  display: inline-block;
`;

const NavSectionHeader = styled.h6`
  font-size: 12px;
  font-family: Nunito;
  font-weight: 300;
  margin: 42px 0 15px 20px;
  color: #858585;
`;

const NavList = styled.ul`
  list-style-type: none;
  display: block;
  margin: 0;
  padding: 0;
`;

const NavListItem = styled.li`
  display: block;
  width: 100%;
  font-weight: 500;
  margin: 0;
  padding: 0;
`;

const NavListItemLink = styled(NavLink)`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #858585;
  padding: 18px 0 17px 20px;
  width: 100%;

  &.selected {
    color: #ffffff;
    background-color: #292929;
  }

  &:hover {
    text-decoration: none;
    color: #ffffff;
    background-color: #292929;
  }

  .ln-icon {
    margin-right: 10px;
  }
`;

const SignOutLink = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;

  & > div {
    width: 220px;
    overflow: hidden;
    display: flex;
    font-size: 14px;
    font-weight: 400;
    color: #858585;
    padding: 10px 10px 10px 10px;
  }
`;

const ProfileAvatar = styled(Avatar)`
  font-family: Nunito;
  margin-right: 10px;
`;

const AvatarTitle = styled.div`
  color: #fff;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const AvatarMeta = styled.div`
  color: #fff;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #9d9d9d;
`;

const EmployerLoggedInLayout = ({
  children,
  match,
  user: { firstName, lastName, email, id, employer },
  updateUser,
}) => {
  const mainUrl = getRouteLevels(match.url, 2);

  useEffect(() => {
    if (!employer) {
      //  TODO: Will be removed after new login approach will be implemented
      API.get(`/employers/users/${id}`).then((res) => {
        updateUser({ employer: res.data });
      });
    }
  });

  return (
    <Wrapper>
      <LeftNav>
        <Link to="/employer">
          <NavHeading>
            Launch <HighlightedText>Now</HighlightedText>
          </NavHeading>
        </Link>
        <NavSectionHeader>MAIN</NavSectionHeader>
        <NavList>
          <NavListItem>
            <NavListItemLink
              to="/employer/dashboard"
              activeClassName="selected"
              data-testid="employer-overview-tab"
            >
              <OverviewIcon className="ln-icon" />
              Overview
            </NavListItemLink>
          </NavListItem>
          <NavListItem>
            <NavListItemLink
              to="/employer/opportunities"
              activeClassName="selected"
              data-testid="employer-opportunities-tab"
            >
              <OpportunitiesIcon className="ln-icon" />
              Opportunities
            </NavListItemLink>
          </NavListItem>
        </NavList>
        <NavSectionHeader>OTHER</NavSectionHeader>
        <NavList>
          {/* <NavListItem>
            <NavListItemLink
              to="/employer/notifications"
              activeClassName="selected"
            >
              <NotificationsIcon className='ln-icon' />Notifications
            </NavListItemLink>
          </NavListItem> */}
          <NavListItem>
            <NavListItemLink to="/employer/settings" activeClassName="selected" data-testid="employer-settings-link">
              <SettingsIcon className="ln-icon" />
              Settings
            </NavListItemLink>
          </NavListItem>
        </NavList>
        <SignOutLink href="/logout">
          <div>
            <ProfileAvatar
              className="profileAvatar"
              round={true}
              size={40}
              AvatarTitle="Title"
              name={`${firstName} ${lastName}`}
              color="#797878"
            />
            <div style={{ width: '100%', overflow: 'hidden' }}>
              <AvatarTitle>Hi {firstName}!</AvatarTitle>
              <AvatarMeta>{email}</AvatarMeta>
            </div>
          </div>
        </SignOutLink>
      </LeftNav>
      <FluidContent>
        <Animation key={mainUrl}>{children}</Animation>
      </FluidContent>
    </Wrapper>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps, { updateUser })(EmployerLoggedInLayout);
