import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Field } from 'formik';

const FormTextInput = ({
  as,
  md,
  controlId,
  label,
  name,
  type,
  inputGroupPrepend,
  dataTestId,
}) => {
  return (
    <Field name={name}>
      {({ field, form, meta }) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (
          <Form.Group as={as} md={md} controlId={controlId}>
            <Form.Label>{label}</Form.Label>
            <InputGroup>
              {inputGroupPrepend}
              <Form.Control
                {...field}
                type={type}
                data-testid={(dataTestId ? dataTestId : '')}
                isValid={form.touched[field.name] && isValid}
                isInvalid={isInvalid}
                feedback={form.errors[field.name]}
              />
              <Form.Control.Feedback type="invalid">
                {form.errors[field.name]}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
        );
      }}
    </Field>
  );
};

FormTextInput.defaultProps = {
  type: 'text',
  inputGroupPrepend: null,
};

export default FormTextInput;
