import { COUNSELOR_DASHBOARD_GET, COUNSELOR_REPORTING_GET } from '../../constants/action-types';

const initialState = {
  loading: true,
  reports: {}
};

function counselorDashboard(state = initialState, action) {
  switch (action.type) {
    case COUNSELOR_DASHBOARD_GET:
      return { ...state, ...action.payload };
    default:
      break;
  }
  switch (action.type) {
    case COUNSELOR_REPORTING_GET:
      return { ...state, reports: action.payload };
    default:
      break;
  }
  return state;
}

export default counselorDashboard;
