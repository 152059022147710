import React, { Component } from 'react';
import { Col, Form as BootstrapForm, Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { Formik, useField } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import styled from 'styled-components';
import {
  Add20Regular as AddIcon,
  CalendarLtr20Regular as CalendarIcon,
  Dismiss20Regular as DismissIcon,
  Edit20Regular as EditIcon,
} from '@fluentui/react-icons';
import moment from 'moment';
import { API } from '../settings';
import Loader from './common/Loader';
import ButtonLn from './common/Button';
import TagSelect from './common/TagSelect';
import DatePickerField from './shared/DatePickerField';
import { getStudents } from '../redux/actions/counselor';
import Tooltip from './Tooltip';
import { CREATE_STUDENT, EDIT_STUDENT, track } from '../utils/mixpanel';

const StyledLabel = styled.label`
  margin-top: 1rem;
  font-size: 14px;
`;

const StyledInput = styled.input`
  &.error {
    border: 1px solid red;
  }
`;

const StyledErrorMessage = styled.div`
  font-size: 14px;
  color: red;
  width: 400px;
  margin-top: 0.25rem;
`;

const ButtonWrapper = styled.div`
  padding-top: 15px;
`;

const Label = styled.div``;

// ensure the checkbox is visible
const ApprovedByCounselorWrapper = styled.div`
  display: flex;
  align-items: center;
  input.form-check-input {
    visibility: visible !important;
  }
`;

const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} className="form-label">
        {label}
      </StyledLabel>
      <StyledInput
        className={`text-input form-control ${
          meta.touched && meta.error ? 'error' : null
        }`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const ApprovedByCounselor = ({ onSelect, value }) => {
  const id = 'approvedByCounselor';

  return (
    <ApprovedByCounselorWrapper
      onClick={() => {
        onSelect(id, !value);
      }}
    >
      <BootstrapForm.Check
        type="checkbox"
        name={id}
        checked={value}
        label="Student approved by counselor"
      />
      <Tooltip id={id}>
        <p>
          <br />
          'When checked, student is flagged as <br />
          'approved by counselor' and does not require <br />
          parent to approve account before student is <br />
          invited to Launch Now
        </p>
      </Tooltip>
    </ApprovedByCounselorWrapper>
  );
};

const FormItemLn = ({ label, children, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} className="form-label">
        {label}
      </StyledLabel>
      {/* <StyledInput
                className={`text-input form-control ${meta.touched && meta.error ? 'error' : null
                    }`}
                {...field}
                {...props}
            /> */}
      {children}
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

class AddNewStudent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      firstName: '',
      lastName: '',
      dob: '',
      grade: '',
      studentEmail: '',
      parentEmail: '',
      approvedByCounselor: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    if (this.props.data && Object.keys(this.props.data).length) {
      this.setDataFromProps();
      this.setState({ editMode: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.data &&
      prevProps.data !== this.props.data &&
      Object.keys(this.props.data).length
    ) {
      this.setState({ editMode: true });
      this.setDataFromProps();
    }
  }

  toggleModal = () => {
    this.setState((state) => ({ modal: !state.modal }));
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSelect = (key, value) => this.setState({ [key]: value });

  onDatePickerChange = (date) => {
    this.setState({ dob: moment(date).format('MM/DD/YYYY') });
  };

  async handleSubmit(
    values,
    { resetForm, setSubmitting, setFieldError, isValid }
  ) {
    setSubmitting(true);
    this.setState({ loading: true });
    const {
      firstName,
      lastName,
      dob,
      studentGrade,
      grade,
      studentEmail,
      parentEmail,
      approvedByCounselor,
    } = this.state;

    API({
      method: 'POST',
      url: `/students`,
      data: {
        firstName,
        lastName,
        dob,
        studentGrade,
        grade,
        studentEmail,
        parentEmail: approvedByCounselor ? null : parentEmail,
        approvedByCounselor,
        schoolId: this.props.schoolId,
      },
    })
      .then((res) => {
        const action = !this.state.editMode ? CREATE_STUDENT : EDIT_STUDENT;

        track(action, {
          studentEmail: this.state.studentEmail,
          approvedByCounselor: approvedByCounselor,
        });

        this.setState({
          firstName: '',
          lastName: '',
          dob: '',
          grade: '',
          approvedByCounselor: false,
          studentEmail: '',
          parentEmail: '',
          loading: false,
          modal: false,
        });
        toast.success(`Student added`);
        resetForm();
        this.props.getStudents(1, '', 'firstName', 'desc', {
          id: this.props.schoolId,
        });
      })
      .catch((err) => {
        let errorMessage = !this.state.editMode
          ? "Couldn't add student"
          : "Couldn't edit student";

        if (err.response && err.response?.data?.message) {
          errorMessage = err.response?.data?.message;
        }

        toast.error(errorMessage);
      })
      .finally(() => {
        this.setState({ loading: false });
        setSubmitting(false);
      });

    this.setState({ formIsSubmitting: false });
  }

  render() {
    const {
      modal,
      loading,
      firstName,
      lastName,
      dob,
      grade,
      studentEmail,
      parentEmail,
      approvedByCounselor,
    } = this.state;

    const { data } = this.props;
    const validationSchema = Yup.object({
      firstName: Yup.string().required('Required'),
      lastName: Yup.string().required('Required'),
      // dob: Yup.string().required('Required'),
      grade: Yup.string().required('Required'),
      studentEmail: Yup.string().email().required('Required'),
      parentEmail: approvedByCounselor
        ? Yup.string().email()
        : Yup.string().email().required('Required'),
    });

    const options = [
      { value: 6, text: '6th' },
      { value: 7, text: '7th' },
      { value: 8, text: '8th' },
      { value: 9, text: '9th' },
      { value: 10, text: '10th' },
      { value: 11, text: '11th' },
      { value: 12, text: '12th' },
      { value: 13, text: '12th+' },
    ];

    return (
      <>
        {this.state.editMode ? (
          <button onClick={this.toggleModal} className="ln-btn">
            <EditIcon className="ln-icon" />
            <span>Edit</span>
          </button>
        ) : (
          <button onClick={this.toggleModal} className="ln-btn primary" data-testid="counselor-add-student-button">
            <AddIcon className="ln-icon" />
            <span>Add Student</span>
          </button>
        )}
        <Modal
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modal}
          onHide={this.toggleModal}
        >
          <Loader active={loading} dimmer={true} />
          <Formik
            enableReinitialize
            validationSchema={validationSchema}
            initialValues={{
              firstName,
              lastName,
              dob: '',
              grade,
              studentEmail,
              parentEmail,
            }}
            onSubmit={this.handleSubmit}
            validateOnMount
          >
            {(props) => (
              <>
                <Modal.Header>
                  <Modal.Title id="contained-modal-title-vcenter">
                    {data ? 'Editing Student' : 'Add New Student'}
                  </Modal.Title>
                  <button
                    onClick={this.toggleModal}
                    className="ln-btn ln-icon-btn default"
                  >
                    <DismissIcon className="ln-icon" />
                  </button>
                </Modal.Header>
                <Modal.Body style={{ maxHeight: '70vh', overflowX: 'hidden' }}
                  data-testid="add-new-student-modal">
                  <div>
                    <Row>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="firstName">
                          <MyTextInput
                            label="First Name"
                            name="firstName"
                            value={firstName}
                            onChange={this.onChange}
                            placeholder=""
                            data-testid="counselor-student-firstName-input"
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="lastName">
                          <MyTextInput
                            label="Last Name"
                            name="lastName"
                            value={lastName}
                            onChange={this.onChange}
                            placeholder=""
                            data-testid="counselor-student-lastName-input"
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="dataOfBirth">
                          <FormItemLn
                            label="Student's Date of Birth"
                            name="dob"
                            value={dob}
                          >
                            <DatePickerField
                              name="dob"
                              value={dob}
                              // onChange={this.onChange}
                              onDatePickerChange={this.onDatePickerChange}       
                            />
                          </FormItemLn>
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={6} xs={6}>
                        <BootstrapForm.Group controlId="studentGrade">
                          <FormItemLn
                            label="Student Grade"
                            name="grade"
                            value={grade}
                          >
                            <TagSelect
                              options={options}
                              onSelect={(value) =>
                                this.onSelect('grade', value)
                              }                            
                              data-testid="counselor-student-grade-input"
                            />
                          </FormItemLn>
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={12} xs={12}>
                        <BootstrapForm.Group controlId="studentEmail">
                          <MyTextInput
                            label="Student Email Address"
                            name="studentEmail"
                            value={studentEmail}
                            onChange={this.onChange}
                            placeholder=""
                            data-testid="counselor-student-studentEmail-input"
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={12} xs={12}>
                        <BootstrapForm.Group controlId="parentEmail">
                          <MyTextInput
                            label="Parent Email Address"
                            name="parentEmail"
                            value={parentEmail}
                            onChange={this.onChange}
                            placeholder=""
                            data-testid="counselor-student-parentEmail-input"
                          />
                        </BootstrapForm.Group>
                      </Col>
                      <Col lg={12} xs={12}>
                        <ApprovedByCounselor
                          value={this.state.approvedByCounselor}
                          onSelect={this.onSelect}
                        />
                      </Col>
                    </Row>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <ButtonLn
                    onClick={() => props.submitForm()}
                    loading={props.isSubmitting}
                    type="submit"
                    content="Add Student"
                    className="ln-btn primary"
                    style={{ float: 'right' }}
                    data-testid="counselor-student-submit-btn"
                  />
                </Modal.Footer>
              </>
            )}
          </Formik>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps, { getStudents })(
  withRouter(AddNewStudent)
);
