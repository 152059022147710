import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import StudentHome from '../components/student/StudentHome/StudentHome';
import StudentCategory from '../components/student/StudentCategory/StudentCategory';
import StudentOpportunity from '../components/student/StudentOpportunity';
import CreateAccount from '../components/student/CreateAccount';

function StudentRoutes(props) {
  let { path } = useRouteMatch();
  return (
    <Switch>
      <Route
        exact
        path={`${path}`}
        render={(props) => <StudentHome {...props} />}
      />
      <Route
        exact
        path={`${path}/category/:categoryId`}
        render={(props) => <StudentCategory {...props} />}
      />
      <Route
        exact
        path={`${path}/opportunity/:opportunityId`}
        render={(props) => <StudentOpportunity {...props} />}
      />
      <Route
        exact
        path={`${path}/create-account`}
        render={(props) => <CreateAccount {...props} />}
      />
    </Switch>
  );
}

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(StudentRoutes);
