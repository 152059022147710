import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

function CopyInviteLink({user, ...props}) {

  const schoolId = user?.userTypeProfile?.schoolId;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const url = `${baseUrl}student/create-account?schoolId=${schoolId}`;

  const copyUrl = async () => {
    await navigator.clipboard.writeText(url);
    toast.success('Invite link copied to clipboard!');
  }
  
  return <button {...props} onClick={copyUrl} className="ln-btn primary" data-testid="invite-link-btn">
    <span>Copy Invite Link</span>
  </button>
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

export default connect(mapStateToProps)(
  withRouter(CopyInviteLink)
);
