import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';
import styled from 'styled-components';
import Avatar from 'react-avatar';
import RatingLn from '../common/RatingLn';
import { fetchUserPic } from '../../features/users/userSlice';

const ProfileHeader = styled.div`
  text-align: left;
  position: relative;
  padding: 20px 11px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  margin-left: 20px;
`;

const ProfileAvatar = styled(Avatar)`
  font-family: Nunito;
  margin-right: 20px;
`;

const ProfileName = styled.h4`
  margin-top: 10px;
  margin-bottom: 5px;
`;

const ProfileEmail = styled.span`
  margin-top: 5px;
  width: 100%;
  display: block;
  color: #9d9d9d;
`;

const PhotoWrapper = styled.div`
  border-radius: 100px;
  position: relative;
  background: white;
  margin-right: 40px;
`;

const StyledAvatar = styled(Avatar)`
  font-family: Nunito;
`;

const ProfilePic = styled.img`
  object-fit: cover;
  border-radius: 100px;
`;

const Photo = ({ user, profilePic, size }) => {

  const style = { height: size, width: size };

  return <PhotoWrapper style={style}>
    {profilePic ? 
    <ProfilePic style={style} src={profilePic} /> :
    <StyledAvatar
      className="profileAvatar"
      round={true}
      size={size}
      name={`${user.firstName} ${user.lastName}`}
      color="#797878"
    />}
  </PhotoWrapper>
}     



const SettingsProfileHeader = (props) => {
  const { user } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserPic(user.id));
  }, []);

  return (
    <ProfileHeader>
      <Photo size={100} user={user} profilePic={props?.user.profilePic} />
      <div>
        <ProfileName>
          {user.firstName} {user.lastName}
        </ProfileName>
        <ProfileEmail>{user.email}</ProfileEmail>
        {/* <RatingLn /> */}
      </div>
    </ProfileHeader>
  );
};

const mapStateToProps = (state) => ({ user: state.user });
export default connect(mapStateToProps)(SettingsProfileHeader);
