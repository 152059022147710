import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spinner } from 'react-bootstrap';

import {
  faChevronLeft,
  faUserCircle,
  faKey,
  faEnvelope,
  faFileAlt,
  faShieldAlt,
  faLifeRing,
  faSignOutAlt,
  faCamera,
} from '@fortawesome/free-solid-svg-icons';
import SignoutPanel from './SignoutPanel';
import Avatar from 'react-avatar';
import { API } from '../settings';
import { toast } from 'react-toastify';


const PageWrapper = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  width: 100%;
  min-height: -webkit-fill-available;
  max-height: -webkit-fill-available;
  padding: 25px;
  position: relative;
`;

const SettingsHeader = styled.div`
  text-align: center;
  position: relative;
  h6 {
    font-size: 1rem;
  }
`;

const BackLink = styled(Link)`
  color: #000000;
  display: inline-block;
  position: absolute;
  top: -3px;
  left: 0;
  font-size: 1rem;
  &:hover {
    color: #000000;
  }
`;

const SettingsSectionTitle = styled.h6`
  font-family: Nunito;
  font-size: 1rem;
  font-weight: bold;
  margin-top: 35px;
  padding-bottom: 5px;
`;

const SettingsLink = styled(Link)`
  color: #000000;
  font-size: 1rem;
  display: block;
  margin-top: 20px;
  &:hover {
    color: #000000;
    text-decoration: none;
  }
`;

const SettingsLabel = styled.label`
  color: #000000;
  font-size: 1rem;
  display: block;
  margin-top: 20px;
  cursor: pointer;
  &:hover {
    color: #000000;
    text-decoration: none;
  }
`;

const SignOutLink = styled(SettingsLink)`
  color: #fe7272;
  &:hover {
    color: #fe7272;
  }
`;


const StudentPhotoWrapper = styled.div`
  height: 100px;
  width: 100px;
  border-radius: 100px;
  position: relative;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 40px;
  background: white;
`;

const StudentAvatar = styled(Avatar)`
  font-family: Nunito;
`;

const ProfilePic = styled.img`
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-radius: 100px;
`;

const StudentPhoto = ({ user, profilePic }) => {
  return <StudentPhotoWrapper>
    {profilePic ? 
    <ProfilePic src={profilePic} /> :
    <StudentAvatar
      className="profileAvatar"
      round={true}
      size={100}
      name={`${user.firstName} ${user.lastName}`}
      color="#797878"
    />}
  </StudentPhotoWrapper>
}     

const StudentSettings = (props) => {
  const [loading, setLoading] = useState(false);
  const [profilePic, setProfilePic] = useState(null);
  const [newProfilePic, setNewProfilePic] = useState(null);
  const [isVisible, setVisibility] = useState(false);
  const history = useHistory();

  const togglePanel = (event) => {
    event.preventDefault();
    setVisibility(!isVisible);
  };

  useEffect(() => {
    const getProfilePic = async () => {
      const result = await API.get(`/users/profile-pic/${props.user.id}`);
      setProfilePic(result.data);
    };

    getProfilePic();
  }, []);

  useEffect(() => {
    const sendFiles = async () => {
      setLoading(true);

      const file = {
        filename: newProfilePic.name,
        filetype: newProfilePic.type,
        body: await toBase64(newProfilePic),
      };
  
      try {
        await API.post(`/users/profile-pic/${props.user.id} `, {
          file
        });

        const result = await API.get(`/users/profile-pic/${props.user.id}`);
        setProfilePic(result.data);
        setNewProfilePic(null);
        toast.success('Profile Pic Changed');
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setLoading(false)
      }
    }
  
    if (newProfilePic) sendFiles();
  }, [newProfilePic, props.user.id])

  return (
    <>
      <PageWrapper>
        <SettingsHeader>
          <BackLink to="/profile">
            <FontAwesomeIcon icon={faChevronLeft} />
          </BackLink>
          <h6>Settings</h6>
        </SettingsHeader>
        <StudentPhoto profilePic={profilePic} user={props.user} />
        <SettingsSectionTitle>Account</SettingsSectionTitle>
        <SettingsLink to="/my-info">
          <FontAwesomeIcon icon={faUserCircle} />
          &nbsp; My Info
        </SettingsLink>
        <SettingsLink to="/change-password">
          <FontAwesomeIcon icon={faKey} />
          &nbsp; Change Password
        </SettingsLink>
        <SettingsLabel style={loading ? { opacity: 0.5, cursor: 'initial' } : {}} for='photo'>
          <FontAwesomeIcon icon={faCamera} />
          &nbsp; Change Profile Pic
          {loading && <Spinner style={{ marginLeft: 10 }} size='sm' animation="border" />}
        </SettingsLabel>
        <input 
          disabled={loading}
          accept="image/*" 
          id='photo' 
          type='file' 
          onChange={(e) => setNewProfilePic(e.target.files[0])}
          style={{ display: 'none' }} />
        <SettingsSectionTitle>&nbsp; Support</SettingsSectionTitle>
        <SettingsLink to="/terms">
          <FontAwesomeIcon icon={faFileAlt} />
          &nbsp; Terms of Use
        </SettingsLink>
        <SettingsLink to="/privacy">
          <FontAwesomeIcon icon={faShieldAlt} />
          &nbsp; Privacy Policy
          
        </SettingsLink>
        <SignOutLink to="/logout" 
          data-testid="student-sign-out"  
          role="link" 
          aria-label="logout"
          onClick={togglePanel}>
          <FontAwesomeIcon icon={faSignOutAlt} rotation={180} />
          &nbsp; Sign Out
        </SignOutLink>
      </PageWrapper>
      <SignoutPanel
        isVisible={isVisible}
        togglePanel={togglePanel}
      />
    </>
  );
};


const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(StudentSettings);
