import React from 'react';
import { connect } from 'react-redux';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';

import { toast } from 'react-toastify';
import { Link, Redirect } from 'react-router-dom';
import LaunchNowHeader from '../components/shared/LaunchNowHeader';
import { API } from '../settings';
import { login } from '../features/users/userSlice';
import ForgotPasswordEmailForm from './ForgotPasswordEmailForm';

const PageContainer = styled(Container)`
  min-height: 100vh;
  min-height: -webkit-fill-available;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Box = styled.div`
  margin-top: 65px;
  width: 330px;
`;

const Welcome = styled.h2`
  margin-top: 40px;
  margin-bottom: 24px;
  font-size: 52px;
  line-height: 62px;
`;

const EmailSubmittedContainer = styled.div`
  background: #46d191;
  border-radius: 0.75rem;
  color: white;
  padding: 1rem;
  p:last-child {
    margin-bottom: 0;
  }
`;

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      message: '',
      loading: false,
      emailSubmitted: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.renderFormOrMessage = this.renderFormOrMessage.bind(this);
  }

  handleSubmit(email) {
    this.setState({
      loading: true,
    });

    API.post(`/users/send-password-reset`, {
      email,
    })
      .then(() => {
        this.setState({
          emailSubmitted: true,
          loading: false,
        });
      })
      .catch((error) => {
        let errorMessage = 'Something went wrong.';

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }

        toast.error(errorMessage);

        this.setState({
          loading: false,
        });
      });
  }

  renderFormOrMessage = () => {
    const { emailSubmitted, loading } = this.state;

    if (emailSubmitted) {
      return (
        <EmailSubmittedContainer data-testid="forgot-password-reminder-sent">
          <p>Your request has been submitted.</p>
          <p>
            If a matching account is found, you will receive an email with a
            link to reset your password shortly.
          </p>
        </EmailSubmittedContainer>
      );
    } else {
      return (
        <>
          <p>
            Enter your registered email below to receive password reset
            instructions...
          </p>
          <ForgotPasswordEmailForm
            handleSubmit={this.handleSubmit}
            loading={loading}
          />
        </>
      );
    }
  };

  render() {
    const { user } = this.props;

    if (user.isLoggedIn) {
      return <Redirect to="/dashboard" />;
    } else {
      return (
        <PageContainer fluid className="p-0 h-100">
          <Row className="h-100">
            <Col lg={6}>
              <LaunchNowHeader />
              <Wrapper>
                <Box>
                  <Link to="/sign-in">&lt; Back</Link>
                  <Welcome>
                    Forgot
                    <br />
                    Password?
                  </Welcome>
                  {this.renderFormOrMessage()}
                </Box>
              </Wrapper>
            </Col>
            <Col lg={6} className="d-none d-lg-block landing-right-col"></Col>
          </Row>
        </PageContainer>
      );
    }
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
