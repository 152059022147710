import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ClusterIcon from './ClusterIcon';

const TitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.88rem;
`;
const TitleLeft = styled.div`
  font-size: 0.88rem;
  font-weight: 600;
`;
const TitleRight = styled.div`
  font-size: 0.75rem;
  padding-top: 1px;
`;

const AllClustersLink = styled(Link)`
  display: inline-block;
  padding: 0.5rem;
  margin-top: -0.5rem;

  &:active {
    text-decoration: none;
  }
`;

const ClustersWrapper = styled.div`
  width: 100%;
  margin-top: 25px;
  -webkit-overflow-scrolling: touch;
  text-align: center;
`;

const ClusterScrollWrapper = styled.div`
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;

  &::-webkit-scrollbar {
    display: none;
  }
`;

const Cluster = styled.div`
  ${'' /* flex: 0 0 auto; */}
  width: 100px;
  height: 100px;
  margin-right: 0.75rem;
  color: #ffffff;
  background-color: #333333;
  border-radius: 12px;
  padding: 10px;
  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  text-align: left;

  h6 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 12px;
    font-weight: 500;
    width: 80px;
    margin-bottom: 0;
  }
`;

const NumberOfOptions = styled.div`
  color: #b5b5b5;
`;

const StudentDashboardRankedClusters = (props) => {
  const { clusters, percentDone, rankedClusters } = props;

  if (
    percentDone > 98 &&
    rankedClusters &&
    rankedClusters.length >= 3 &&
    clusters &&
    clusters.length > 0
  ) {
    return (
      <ClustersWrapper data-testid="ranked-clusters">
        <TitleBar>
          <TitleLeft>Categories</TitleLeft>
          <TitleRight>
            <AllClustersLink to="clusters"
            data-testid="clusters-see-all">
              See all ({clusters.length}) &gt;
            </AllClustersLink>
          </TitleRight>
        </TitleBar>
        <ClusterScrollWrapper>
          {clusters.map((cluster, i) => {
            const backLink = '/dashboard';

            return (
              <Link
                to={{
                  pathname: `/clusters/${cluster.id}`,
                  query: { backLink },
                }}
                key={cluster.name}
                data-testid={`cluster-${cluster.id}`}
              >
                <Cluster>
                  <ClusterIcon clusterKey={cluster.key} name={cluster.name} />
                  {/*<div>*/}
                  {/*  <h6>{cluster.name}</h6>*/}
                  {/*  <NumberOfOptions>10+ options</NumberOfOptions>*/}
                  {/*</div>*/}
                </Cluster>
              </Link>
            );
          })}
        </ClusterScrollWrapper>
      </ClustersWrapper>
    );
  }

  return null;
};

export default StudentDashboardRankedClusters;
