import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import styled from 'styled-components';

const TooltipWrapper = styled.div`
  background: white;
  border-radius: 10px;
  border: 1px solid lightgrey;
  padding: 10px;
  display: flex;
  flex-direction: column;
  display: grid;
  gap: 5px;
  grid-auto-flow: row;
`;

const Row = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 10px;
  align-items: center;
  font-size: 13px;
  
`;

const Box = styled.div`
  background: red;
  height: 15px;
  width: 15px;
  border-radius: 4px;
`;

const colors = ['#C73D3D', '#FC5A5A', '#FC985A', '#FFC44F', '#FFC44F'];

const xAxisFormatter = (value) => `${value === 13 ? '12+' : value + 'th'} grade`;

const CustomTooltip = ({ active, payload, tags = [], formatter}) => {

  if (active) {
    return (
        <div style={{ padding: 10 }}>
          <TooltipWrapper>
            { payload?.map((p, i) => 
              <Row>
                <Box style={{ backgroundColor:p.fill}} />
                <div>{tags[i] || p.key}</div>
                <div>{formatter(p.value)}</div>
              </Row> 
            )}
           </TooltipWrapper>
        </div>
    );
  }

  return null
};

const getPath = (x, y, width, height) => {

  const radius = height === 0 || width < 0 ? 0 : width * 0.3

  return `M ${x} ${y + radius}
  L ${x} ${y + height} 
  L ${x + width} ${y + height}
  L ${x + width} ${y + radius} 
  Q ${x + width} ${y} ${x + width - radius} ${y} 
  L ${x + radius} ${y}
  Q ${x} ${y} ${x} ${y + radius}
  Z`;
};

const Title = styled.h4`
  font-size: 15px;
  margin-left: 25px;
  margin-bottom: 30px;
`;

const BorderBar = (props) => {
  const { fill, x, y, width, height } = props;
  
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
}

const percentFormatter = (data) => {
  return data?.map((d) => ({
    ...d,
    percent: Math.floor(d?.percentOnboarded) || 0
  }))
}

const BarChartLN = ({ 
  formatter = percentFormatter, 
  singleValueFormatter = (v) => Math.ceil(v) + '%',
  data, 
  keys,
  tags,
  loading, 
  domain = [0, 100],
  multipleBars,
  style = {},
  yAxisFormatter = (value) => `${value}%`,
  title = 'Chart', 
  ...props }) => {
  const input = formatter(data);

  if (loading) {
    return null;
  }

  const mapper = (fill) => (entry, index) => {
    const { percent } = entry;
    return (
      <Cell
        key={`cell-${index}`}
        fill={fill ? fill : colors[Math.floor(percent / 25)]}
      />
    );
  }

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '1000px',
        boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.02)',
        padding: '25px 0',
        height: '370px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
        ...style,
      }}
    >
      <Title>{title}</Title>
      <ResponsiveContainer width="96%" height="100%">
        <BarChart
          width={400}
          height={200}
          data={input}
          barCategoryGap={25}
        >
          <XAxis tickLine={false} tickMargin={15} tick={{fontSize: 12}} dataKey="grade" tickFormatter={xAxisFormatter} />
          <YAxis tickLine={false} axisLine={false} domain={domain} tick={{fontSize: 12}} tickFormatter={(yAxisFormatter)} />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          {!multipleBars && 
            <Bar shape={<BorderBar />} dataKey={'percentOnboarded'} fill='#82ca9d'>
            {input?.map(mapper)}
            </Bar>
          }
          {multipleBars && 
            <>
            <Bar shape={<BorderBar />} dataKey={keys[0]} fill='orange'>
              {input?.map(mapper('orange'))}
            </Bar>
            <Bar shape={<BorderBar />} dataKey={keys[1]} fill='green'>
              {input?.map(mapper('green'))}
            </Bar>
            </>
          }
        <Tooltip 
          tags={tags} 
          cursor={{ fill: 'rgba(0, 0, 0, 0.05)' }} 
          content={<CustomTooltip formatter={singleValueFormatter} />} 
        />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default BarChartLN;
