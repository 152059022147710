import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import SubcategorySkillsCloud from './SubcategorySkillsCloud';

const MAX_WIDTH = 576;

const BackgroundOverlay = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: none;
  background-color: #000000;
  opacity: 0.5;
  z-index: 100;
`;

const Overlay = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  display: none;
  justify-content: center;
  background: none;
  opacity: 1;
  z-index: 200;
`;

const PanelWrapper = styled.div`
  &.panelVisable {
    display: block;
    position: relative;
    min-height: 100vh;
    /* mobile viewport bug fix */
    min-height: -webkit-fill-available;

    .overlay {
      display: flex;
    }

    .panelContainer {
      display: flex;
      transform: translate3d(0, 0, 0);
      transition-delay: 0s;
    }
  }
`;

const PanelContainer = styled.div`
  display: none;
  position: absolute;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  max-width: ${MAX_WIDTH}px;
  opacity: 1;
  width: 100%;
  height: 80%;
  max-height: 80%;
  z-index: 301;
  transform: translate3d(0, 100%, 0);
  transition: transform 0.2s 0.2s;
  background-color: #ffffff;
  border-top-left-radius: 25px;
  border-top-right-radius: 25px;
`;

const PanelTitleWrapper = styled.div`
  width: 100%;
  padding: 25px;
  display: flex;
  position: static;
  top: 0;
  flex: 0 1 auto;
  top: 0;
  left: 0;
`;

const PanelTitle = styled.h3`
  width: 85%;
  overflow-wrap: break-word;
`;

const PanelToggleButtonWrapper = styled.div`
  width: 15%;
  text-align: right;
`;

const PanelToggleButton = styled.button`
  flex-grow: 0;
  border-radius: 10px;
  padding: 0.75rem;
  font-size: 1.25rem;
  line-height: 1.25rem;
  border: none;
  background-color: #f2f3f5;

  &:active {
    border: none;
  }
`;

const PanelContent = styled.div`
  padding: 25px 25px 125px;
  overflow-y: auto;
`;

const DescriptionHeader = styled.h6`
  margin-bottom: 1rem;
`;
const Description = styled.div`
  margin-bottom: 1.5rem;
`;

const SkillsHeader = styled.h6`
  margin-bottom: 1rem;
`;

const SubcategoryPanel = (props) => {
  const { showPanel, skills, subcategory, toggleSubcategoryPanel, user } =
    props;

  let panelVisibilityClassName = '';

  if (showPanel) {
    panelVisibilityClassName = 'panelVisable';
  }

  const displayDescription = () => {
    const { description } = subcategory;

    if (description) {
      return <p>{description}</p>;
    }

    return null;
  };

  if (subcategory) {
    return (
      <PanelWrapper className={panelVisibilityClassName}>
        <BackgroundOverlay className="overlay" />
        <Overlay
          className="overlay"
          // onClick={() => {
          //   toggleSubcategoryPanel(subcategory.id);
          // }}
        >
          <PanelContainer className="panelContainer">
            <PanelTitleWrapper>
              <PanelTitle>{subcategory.name}</PanelTitle>
              <PanelToggleButtonWrapper>
                <PanelToggleButton
                  onClick={() => {
                    toggleSubcategoryPanel(subcategory.id);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </PanelToggleButton>
              </PanelToggleButtonWrapper>
            </PanelTitleWrapper>
            <PanelContent>
              <DescriptionHeader>What it means</DescriptionHeader>
              <Description>{displayDescription()}</Description>
              <SkillsHeader>Skills</SkillsHeader>
              <SubcategorySkillsCloud skills={skills} />
            </PanelContent>
          </PanelContainer>
        </Overlay>
      </PanelWrapper>
    );
  } else {
    return null;
  }
};

export default SubcategoryPanel;

SubcategoryPanel.propTypes = {
  showPanel: PropTypes.bool,
  skills: PropTypes.array,
  subcategory: PropTypes.object,
  toggleSubcategoryPanel: PropTypes.func,
  user: PropTypes.object,
};
