import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-bootstrap';
import {
  ArrowUpload20Regular as UploadIcon,
  Dismiss20Regular as DismissIcon,
  Send24Regular as SendIcon,
} from '@fluentui/react-icons';
import Loader from '../../common/Loader';
import Dropzone from 'react-dropzone';
import styled from 'styled-components';
import FileItem from '../../common/FileItem';

import { API } from '../../../settings';
import { toast } from 'react-toastify';
import States from '../../common/Selects/States';

const sectionStyle = {
  width: '100%',
  height: '200px',
  overflow: 'auto',
  textAlign: 'center',
  margin: 0,
  borderRadius: '15px',
  padding: '10px',
  border: 'dashed 1px #c6c6c6',
};

const MetaText = styled.p`
  margin: 0;
  font-size: 12px;
  color: #858585;
`;

// lastModified: 1624001260847
// lastModifiedDate: Fri Jun 18 2021 12:27:40 GMT+0500 (Uzbekistan Standard Time) {}
// name: "Web capture_18-6-2021_122740_localhost.jpeg"
// path: "Web capture_18-6-2021_122740_localhost.jpeg"
// size: 74784
// type: "image/jpeg"
// webkitRelativePath: ""

class UploadDocumentsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      modal: false,
      files: [],
      stateId: -1,
      employerData: {},
    };
    this.sendFiles = this.sendFiles.bind(this);
  }

  componentDidMount() {
    this.getEmployer();
  }

  getEmployer = () => {
    API.get(`/employers/users/${this.props.user.id}`).then((res) =>
      this.setState({ employerData: res.data })
    );
  };
  toggleModal = () => this.setState((state) => ({ modal: !state.modal }));
  onSelect = (key, value) => this.setState({ [key]: value });
  uploadDocuments = (files) =>
    this.setState((state) => ({ files: state.files.concat(files) }));
  removeFile = (index) => {
    let { files } = this.state;
    files.splice(index, 1);
    this.setState({ files: files });
  };

  async sendFiles() {
    const { files } = this.state;
    this.setState({ loading: true });
    const {
      user: { userId },
    } = this.props;
    let documents = [];
    for (let i = 0; i < files.length; i++) {
      documents.push({
        filename: files[i].name,
        filetype: files[i].type,
        body: await toBase64(files[i]),
      });
    }
    API.post(`/employers/uploads/${this.state.employerData.id} `, {
      stateId: this.state.stateId,
      documents,
    })
      .then((res) => {
        // handle response
        this.setState({ files: [], loading: false, stateId: -1, modal: false });
        toast.success('Files uploaded');
        if (this.props.onFinish) {
          this.props.onFinish();
        }
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      })
      .finally(() => this.setState({ loading: false }));
  }

  render() {
    const { modal, loading, files, stateId } = this.state;
    const tempRenderUploaded = () => {
      return (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            height: '100%',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'center',
            }}
          >
            <div style={{ position: 'relative', width: '130px' }}>
              <img
                draggable="false"
                style={{
                  height: '70px',
                  transform: 'rotate(-15deg)',
                  float: 'left',
                }}
                src="/file_type_icons/pdf.svg"
                alt=""
              />
            </div>
            <div style={{ textAlign: 'left' }}>
              <h5 className="no-select">
                Drag your files here, or{' '}
                <text style={{ color: '#ffc34e' }}>browse</text>
              </h5>
              <MetaText className="no-select">Supports: PDF</MetaText>
            </div>
          </div>
        </div>
      );
    };
    const filesLength = files.length;
    return (
      <>
        <button onClick={this.toggleModal} className="ln-btn primary" data-testid="upload-documents">
          <UploadIcon className="ln-icon" />
          <span>Upload new documents</span>
        </button>
        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modal}
          onHide={this.toggleModal}
        >
          <Loader active={loading} dimmer={true} />
          <Modal.Header>
            <div>
              <Modal.Title id="contained-modal-title-vcenter">
                Upload new documents
              </Modal.Title>
              <MetaText>
                Please upload your signed/approved documents from your local
                government
              </MetaText>
            </div>
            <States
              style={{ width: 'min-content' }}
              value={stateId}
              onChange={(e) => this.onSelect('stateId', e.target.value)}
            />
            <div className="horizontal-flex align-center children-mg-l">
              {/* <button className='ln-btn default'><DownloadIcon className='ln-icon' /><span>Download Documents</span></button> */}
              <button
                onClick={this.toggleModal}
                className="ln-btn ln-icon-btn default"
              >
                <DismissIcon className="ln-icon" />
              </button>
            </div>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '70vh', overflow: 'auto' }}>
            <div style={{ position: 'relative' }}>
              {loading ? (
                <div
                  style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '100%',
                      width: '100%',
                    }}
                  >
                    <Loader active />
                  </div>
                </div>
              ) : null}
              <Dropzone
                onDrop={(acceptedFiles) => this.uploadDocuments(acceptedFiles)}
                style={{ height: '200px', overflow: 'auto' }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} data-testid="drop-documents"/>
                      <div style={sectionStyle}>{tempRenderUploaded()}</div>
                    </div>
                  </section>
                )}
              </Dropzone>
              {filesLength ? (
                <div
                  style={{
                    height: '200px',
                    overflowY: 'auto',
                    width: '100%',
                    padding: '10px',
                    marginTop: '10px',
                  }}
                >
                  {files.map((file, i) => (
                    <FileItem
                      key={i}
                      onRemove={() => this.removeFile(i)}
                      name={file.name}
                    />
                  ))}
                </div>
              ) : null}
            </div>
          </Modal.Body>
          <Modal.Footer
            style={{
              justifyContent: 'center',
            }}
          >
            {filesLength && stateId > 0 ? (
              <button
                onClick={this.sendFiles}
                disabled={loading}
                className="ln-btn primary"
                data-testid="send-documents"
              >
                <SendIcon className="ln-icon" />
                <span>Send Documents</span>
              </button>
            ) : null}
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });

export default connect(mapStateToProps)(UploadDocumentsModal);

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    console.log('file: ' + file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

async function getBase64(fileUrl, res, error) {
  let file = new File([fileUrl], 'document.docx');
  var reader = new FileReader();
  // reader.readAsDataURL(file);
  // console.log('buffer: ' + reader.readAsArrayBuffer(file))
  reader.readAsArrayBuffer(file);
  reader.onload = function () {
    res(reader.result);
  };
  reader.onerror = function () {
    console.log(reader.error);
  };
  return file;
}
