import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';

const MAX_WIDTH = 576;

const Wrapper = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  margin: 0 auto;
  max-width: ${MAX_WIDTH}px;
  overflow-y: scroll;
  overflow-x: scroll;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  } /* Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (min-width: 600px) {
    box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
  }
`;


const Button = styled.a`
    background: gray;
    padding: 10px;
    border-radius: 5px;
    font-weight: 800;
    max-width: 400px;
    min-width: 200px;
    margin-top: 20px;
    color: white;
    text-align: center;
    align-self: center;
    cursor: pointer;
    
    &:hover {
        color: white;
        text-decoration: none;
    }
`;  


function MinorAccount({ user }) {

  const { parentEmail } = user.userTypeProfile;

  return <Wrapper>
    <h2>Parent approval required</h2>
    <p>In order to use your Launch Now Account, we require your parents to approve your account. We sent an email with instructions to <b>{parentEmail}</b>.</p>
    <Button href='/logout'>Sign Out</Button>
  </Wrapper>

}

const mapStateToProps = (state) => ({
  user: state.user,
})

export default connect(mapStateToProps)(MinorAccount);