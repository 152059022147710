import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import styled from 'styled-components';
import dotenv from 'dotenv';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { compareLastNames, uniqueStudentsByEmail } from '../utils/arrayOps';
import debounce from 'lodash.debounce';
import StudentPercentDone from './StudentPercentDone';
import { getStudents } from '../redux/actions/counselor';

dotenv.config();

const StudentsWrapper = styled.div`
  background: #ffffff;
  border-radius: 1.25rem;

  table,
  th,
  td {
    border: none;
  }

  thead {
    th {
      border: none;
    }
  }

  tbody:before {
    content: '@';
    display: block;
    line-height: 0.5rem;
    text-indent: -99999px;
  }

  td {
    font-size: 0.875rem;
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }

  td.firstName,
  td.lastName {
    font-size: 1rem;
    font-weight: bold;
    background: none;
  }

  td.grade,
  td.percentDone {
    span {
      background: #fbfbfb;
      display: inline-block;
      padding: 0.2rem 1rem;
      border-radius: 8px;
    }
  }
`;

const FilterWrapper = styled.div`
  position: relative;
`;

const Filter = styled.input`
  margin-bottom: 1.25rem;
  width: 100%;

  font-size: 0.875rem;
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
  padding-left: 2.25rem;
  border-radius: 0.625rem;
  border: 1px solid #e2e2ea;
  font-family: 'Nunito';

  &::placeholder {
    color: #929292;
    opacity: 1;
  }
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  top: 14px;
  left: 0.625rem;
  color: #92929d;
`;

const BootstrapTableWrapper = styled.div`
  .table td {
    overflow-wrap: break-word;
  }

  .table th {
    background: #fbfbfb;
    color: #858585;
    font-size: 0.6875rem;

    &:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    &:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }

  .react-bootstrap-table-pagination {
    margin-top: 2rem;
    justify-content: center;

    div {
      display: none;
    }

    div.react-bootstrap-table-pagination-list {
      display: flex;
      justify-content: center;

      ul {
        float: none;
      }
    }

    .page-link {
      border-color: #797878;
      color: #797878;
    }

    .page-item.active .page-link {
      color: #ffffff;
      background-color: #000000;
      border-color: #000000;
    }
  }
`;

class Students extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: '',
      filteredStudents: [],
      loading: false,
      students: [],
    };
    this.getStudentsDebounce = debounce(this.getStudentsDebounce, 700);
    this.handleFilterChange = this.handleFilterChange.bind(this);
  }

  loadStudents = () => {
    this.props.getStudents(1, '', 'firstName', 'desc', {
      id: this.props.schoolId,
    });
  };

  componentDidMount() {
    this.loadStudents();
  }

  handleFilterChange = (event) => {
    const { students } = this.props;
    const filter = event.target.value;

    const filteredByFirstName = students.filter(
      (student) =>
        student.firstName &&
        student.firstName.toLowerCase().includes(filter.toLowerCase())
    );

    const filteredByLastName = students.filter(
      (student) =>
        student.lastName &&
        student.lastName.toLowerCase().includes(filter.toLowerCase())
    );

    // Add filterered arrays, sort by last name and ensure uniqueness
    const filteredStudents = uniqueStudentsByEmail(
      [...filteredByFirstName, ...filteredByLastName].sort(compareLastNames)
    );

    this.setState({ filter, filteredStudents });
  };

  getStudentsDebounce = (searchValue, sortBy, sort) => {
    this.props.getStudents(this.props.page, searchValue, sortBy, sort, {
      id: this.props.schoolId,
    });
  };

  onTableSort = (order, dataField) => {
    if (dataField === this.props.sortBy) {
      this.props.sort === 'desc' ? (order = 'asc') : (order = 'desc');
    }
    this.getStudentsDebounce(this.props.searchValue, dataField, order);
  };

  onSearch = (e) => {
    this.props.setSearchValue(e.target.value);
    this.getStudentsDebounce(e.target.value, 'firstName', 'desc');
  };

  render() {
    let { filter, filteredStudents } = this.state;

    if (!filteredStudents.length && !filter) {
      filteredStudents = this.props.students;
    }

    const firstNameFormatter = (cell, row) => {
      return (
        <Link to={`${this.props.studentPageLinkBase}${row.uuid}`}>{cell}</Link>
      );
    };

    const lastNameFormatter = (cell, row) => {
      return (
        <Link to={`${this.props.studentPageLinkBase}${row.uuid}`}>{cell}</Link>
      );
    };

    const gradeFormatter = (cell, row) => {
      if (cell == 13) cell = '12+';
      return <span>{cell}th</span>;
    };

    const percentDoneFormatter = (cell, row) => {
      return <StudentPercentDone percentDone={cell} />;
    };

    const columns = [
      {
        text: 'ID',
        dataField: 'id',
        sort: true,
        classes: 'id',
      },
      {
        text: 'First Name',
        dataField: 'firstName',
        sort: true,
        formatter: firstNameFormatter,
        classes: 'firstName',
      },
      {
        text: 'Last Name',
        dataField: 'lastName',
        sort: true,
        formatter: lastNameFormatter,
        classes: 'lastName',
      },
      {
        text: 'Grade',
        dataField: 'grade',
        sort: true,
        formatter: gradeFormatter,
        classes: 'grade',
      },
      {
        text: 'Assessment Status',
        dataField: 'percentDone',
        sort: true,
        formatter: percentDoneFormatter,
        classes: 'percentDone',
      },
    ];

    const paginationConfig = {
      sizePerPage: 100,
      hideSizePerPage: true,
    };

    return (
      <Container className="p-0 h-100 ">
        <Row>
          <Col>
            <StudentsWrapper>
              <FilterWrapper>
                <Filter
                  type="text"
                  value={filter}
                  placeholder="Search by First or Last Name..."
                  onChange={this.handleFilterChange}
                />
                <SearchIcon icon={faSearch} size="1x" />
              </FilterWrapper>
              <BootstrapTableWrapper>
                <BootstrapTable
                  columns={columns}
                  data={filteredStudents}
                  keyField="email"
                  pagination={paginationFactory(paginationConfig)}
                />
              </BootstrapTableWrapper>
            </StudentsWrapper>
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  students: state.counselor.students?.students,
});

export default connect(mapStateToProps, { getStudents })(Students);
