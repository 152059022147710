import React, { useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { getStates } from '../../../redux/actions/options';

function States(props) {
  useEffect(() => {
    if (!props.states.length) {
      props.getStates();
    }
  });
  return (
    <>
      {props.label ? <Form.Label>{props.label}</Form.Label> : null}
      <Form.Control {...props} as="select" data-testid="document-state-selector">
        <option disabled value={-1}>
          Select state
        </option>
        {props.states.map((state, i) => (
          <option value={state.id}>{state.code}</option>
        ))}
      </Form.Control>
    </>
  );
}

function mapStateToProps(state) {
  return {
    states: state.options.states.APIdata,
  };
}

export default connect(mapStateToProps, { getStates })(States);
