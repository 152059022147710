import React, { useState, useEffect, useRef } from 'react';

const useCollapsible = (visible) => {
  const [isCollapsing, setIsCollapsing] = useState(false);
  const [height, setHeight] = useState('auto');
  const [collapsedHeight, setCollapsedHeight] = useState(0);
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    if (visible) {
      setHeight(`${element.scrollHeight}px`);
      setIsCollapsing(false);
    } else {
      setCollapsedHeight(element.offsetHeight);
      setHeight('0px');
      setIsCollapsing(true);
    }
  }, [visible]);

  const handleTransitionEnd = () => {
    if (!visible) {
      setIsCollapsing(false);
    }
  };

  return {
    ref,
    style: {
      height: isCollapsing ? '0px' : height,
      overflow: 'hidden',
      transition: 'height 0.3s ease',
    },
    onTransitionEnd: handleTransitionEnd,
  };
};

export default useCollapsible;