import React, { useRef } from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import styled from 'styled-components';

const SectionTitle = styled.h6`
  color: #000000;
  margin-bottom: 2rem;
`;

const StyledRow = styled(Row)`
  padding: 25px;
`;

export default function OpportunityDetails(props) {
  const {
    values: { description },
    onChange,
  } = props;
  return (
    <>
      <SectionTitle>Opportunity Details</SectionTitle>
      <Form>
        <Row>
          <Col xs={12}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Opportunity Description</Form.Label>
              <Form.Control
                onChange={(e) => onChange('description', e.target.value)}
                value={description}
                as="textarea"
                style={{ resize: 'none' }}
                placeholder="Describe the responsibilities of this opportunity, required work experience or education."
                rows={8}
                data-testid="opportunity-form-describe"
              />
            </Form.Group>
            {/* <Form.Group controlId="formBasicEmail">
                            <Form.Label>Technical Skills (The more you add the better matches we will find)</Form.Label>
                            <Form.Control as="textarea" style={{ resize: 'none' }} placeholder="Describe the responsibilities of this opportunity, required work experience or education." rows={1} />
                        </Form.Group> */}
            {/* <Form.Group controlId="formBasicCheckbox">
                            <Form.Label>Interpersonal Skills (Select at least three)</Form.Label>
                            <Row>
                                <Col lg={3} md={4} xs={6}>
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="2" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                </Col>
                                <Col lg={3} md={4} xs={6}>
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                </Col>
                                <Col lg={3} md={4} xs={6}>
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                </Col>
                                <Col lg={3} md={4} xs={6}>
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                    <Form.Check type="checkbox" name="1" label="My business address" />
                                </Col>
                            </Row>
                        </Form.Group> */}
          </Col>
        </Row>
      </Form>
    </>
  );
}
