import React, { useState } from 'react';
import styled from 'styled-components';
import { useField, useFormikContext } from 'formik';


const Wrapper = styled.div``;

const Option = styled.div`
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  transition: ease 0.2s;
  border-radius: 8px;
  margin: 3px;
  font-size: 12px;
  background: rgba(0,0,0,0.03);
  &.selected {
    background: #333333;
    color: #fff;
  }
`;

function FormikSelect(props) {
  
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);


  const onSelect = (option) => {
    if (props.multiple) {
      const indexOfValue = field.value?.indexOf(option.value);
      if (indexOfValue > -1) {
        setFieldValue(field.name, field.value.splice(indexOfValue, 1));
      } else {
        setFieldValue(field.name, [...field.value, option.value]);
      }
    } else {
      setFieldValue(field.name, [option.value])
    }
  };

  let { options } = props;
  const value = field.value;
    

    return (
      <Wrapper>
        {Array.isArray(options)
          ? options.map((option, i) => {
              let className = '';
              if (
                value?.indexOf(option.value) > -1
              ) {
                className += 'selected';
              } 
              return (
                <Option
                  key={i}
                  className={`${className}`}
                  value={option.value}
                  onClick={() => onSelect(option)}
                >
                  {option.text}
                </Option>
              );
            })
          : null}
      </Wrapper>
    );
  
}

export default FormikSelect;
