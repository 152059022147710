import React from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import TinderCard from 'react-tinder-card';

import StudentAssessmentInterstitial from './StudentAssessmentInterstitial';
import ProgressBar from './ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { API } from '../settings';
import { updatePercentDone } from '../features/users/userSlice';
import { FINISH_ASSESSMENT, RESTART_ASSESSMENT, START_ASSESSMENT, track } from '../utils/mixpanel';

const PageWrapper = styled.div`
  padding-top: 25px;
  height: 100vh;
  flex-flow: column nowrap;
  display: flex;
  align-items: center;
`;

const ButtonRow = styled.div`
  max-width: 280px;
  display: flex;
  margin-top: 50px;
  margin-bottom: auto;
  width: 100%;
  justify-content: space-between;
`;

const ProgressRow = styled.div`
  display: flex;
  margin-top: auto;
  width: 100%;
  max-width: 360px;
`;

const RoundButton = styled.a`
  display: flex;
  justify-content: center;
  width: 100px;
  height: 100px;
  background: #f2f3f5;
  border-radius: 100px;
  align-items: center;

  opacity: ${props => (props.disabled ? '.5' : '1')};
  pointer-events: ${props => (props.disabled ? 'none' : 'auto')};
  cursor: ${props => (props.disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    opacity: ${props => (props.disabled ? '.5' : '1')};
    background: #e1e2e4;
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

const SectionHeader = styled.div`
  padding: 15px 0 15px;
  text-align: center;
`;

const SectionTitle = styled.h2`
  font-family: Nunito;
  font-size: 22px;
  color: #000000;
  margin: 0;
  padding: 0;
`;

const SectionSubTitle = styled.span`
  display: inline-block;
  margin-top: 5px;
  font-size: 16px;
`;

// TODO: send this back as a flat array

const studentCompletedQuestionsWithSections = [
  {
    id: 1,
    key: 'SECTION_1',
    title: 'Section 1 Title',
    questions: [
      {
        id: 1,
        key: 'I_LIKE_TO_WORK_ON_CARS',
        copy: 'I like to work on cars',
        answer: 'Y',
      },
      {
        id: 2,
        key: 'I_LIKE_TO_DO_PUZZLES',
        copy: 'I like to do puzzles',
        answer: 'N',
      },
    ],
  },
  {
    id: 2,
    key: 'SECTION_2',
    title: 'Section 2 Title',
    questions: [
      {
        id: 3,
        key: 'I_AM_GOOD_AT_WORKING_INDEPENDENTLY',
        copy: 'I am good at working independently',
        answer: 'Y',
      },
    ],
  },
];

class StudentAssessment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      allSectionsQuestionsCount: 0,
      answersCount: 0,
      assessmentKey: '',
      cardRefs: [],
      childRefs: [],
      completedQuestionsWithSections: [],
      currentSectionIndex: 0,
      currentSectionTitle: '',
      currentQuestionSectionIndex: 0,
      interstitialIsVisible: false,
      assessmentComplete: false,
      loading: true,
      nextQuestionSectionIndex: 0,
      percentDone: 0,
      postRequestInProgress: '',
      questions: [],
      questionsCount: 0,
      questionsWithSections: [],
      remainingQuestions: [],
      sectionsCount: 0,
    };

    this.swipe = this.swipe.bind(this);
    this.swiped = this.swiped.bind(this);
    this.storeSwipe = this.storeSwipe.bind(this);
    this.closeInterstitial = this.closeInterstitial.bind(this);
  }

  componentDidMount() {
    const { isDemo, user } = this.props;
    const { uuid } = user;


    let questionsUrl = `/students/${uuid}/assessment-questions`;

    if (isDemo) {
      questionsUrl = `/students/assessment-test-questions`;
    }

    // TODO: run network request here to get student's current answers state
    API.get(questionsUrl)
      .then((response) => {
        const { answersCount, key, questions, questionsCount } = response.data;

        const sections = questions;
        const sectionsCount = sections.length;
        let currentSectionIndex = 0;
        const remainingQuestions = [];

        if (answersCount > 0) {     
          track(RESTART_ASSESSMENT);
        } else {
          track(START_ASSESSMENT)
        }

        console.log('running through questions with sections');
        console.log(studentCompletedQuestionsWithSections);

        sections.forEach((section, index) => {

          const studentAnswerKeys = [];

          // store keys of all student answers
          if (studentCompletedQuestionsWithSections[index]) {
            studentCompletedQuestionsWithSections[index].questions.forEach(
              (question) => {
                studentAnswerKeys.push(question.key);
              }
            );
          }

          sections[index].questions.forEach((question) => {
            if (!studentAnswerKeys.includes(question.key)) {
              remainingQuestions.push({
                sectionTitle: sections[index].title,
                sectionSubTitle: sections[index].subTitle,
                sectionInterstitialCopy: sections[index].interstitialCopy,
                sectionIndex: index,
                id: question.id,
                key: question.key,
                copy: question.copy,
              });
            }
          });
        });

        // Reverse -- because we're stacking a deck of cards
        remainingQuestions.reverse();

        // associating refs with questionId, hopefully these are unique
        const cardRefs = remainingQuestions.map((question) => ({
          questionId: question.id,
          ref: React.createRef()
        }));

        console.log('COMPLETED QUESTIONS COUNT');
        console.log(answersCount);

        this.setState({
          answersCount: answersCount,
          assessmentKey: key,
          sectionsCount,
          currentSectionIndex,
          completedQuestionsWithSections: [],
          loading: false,
          questionsCount: questionsCount,
          remainingQuestions,
          cardRefs,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });

        // TODO: handle error here
        console.log(error);
      });
  }

  swipe = (dir, questionId) => {
    const { cardRefs } = this.state;

    const cardRefToSwipe = cardRefs.find(el => el.questionId === questionId);
    cardRefToSwipe.ref.current.swipe(dir)
  };

  swiped(direction, questionId, questionKey) {
    this.storeSwipe(direction, questionId, questionKey);
  }

  storeSwipe(direction, questionId, questionKey) {
    const { answersCount, assessmentKey } = this.state;
    const { updatePercentDone, user } = this.props;
    const storeAnswerUrl = `/students/answers`;
    let answer = 'Y';

    if (direction === 'left') {
      answer = 'N';
    }

    console.log(
      `Storing swipe for question: ${questionId} / ${questionKey} in direction: ${direction}. Answer: ${answer}.`
    );

    this.setState({
      postRequestInProgress: 'disabled'
    });

    API.post(storeAnswerUrl, {
      assessmentKey,
      answer,
      questionKey,
      questionId,
      uuid: user.uuid,
    })
      .then((response) => {
        const responseData = response.data;
        const answersCount = responseData.answersCount;
        const previousQuestionId = responseData.questionId;
        const {
          sectionIndex,
          questionNumberInSection,
          totalQuestionsInSection,
          nextQuestionId,
          assessmentComplete
        } = responseData.nextQuestion;

        const tmpRemainingQuestions = this.state.remainingQuestions.filter(
          (question) => question.id !== previousQuestionId
        );

        this.setState({
          interstitialIsVisible: questionNumberInSection === 1 || questionNumberInSection === null,
          assessmentComplete,
          currentQuestionSectionIndex: sectionIndex,
          answersCount,
          postRequestInProgress: '',
          remainingQuestions: tmpRemainingQuestions
        });
        // Update percentDone in Redux
        // updatePercentDone(response.data);
      })
      .catch((error) => {
        // TODO: handle error
        console.log(error);
      });
  }

  closeInterstitial() {
    const { assessmentComplete } = this.state;

    if (assessmentComplete) {
      track(FINISH_ASSESSMENT);
      this.props.history.push('/dashboard');
    } else {
      this.setState({
        interstitialIsVisible: false,
      });
    }
  }

  render() {
    const {
      answersCount,
      cardRefs,
      currentQuestionSectionIndex,
      interstitialIsVisible,
      assessmentComplete,
      loading,
      nextQuestionSectionIndex,
      postRequestInProgress,
      remainingQuestions,
      sectionsCount,
      questionsCount,
    } = this.state;

    console.log(' -- RENDER --');
    console.log(answersCount);
    console.log(questionsCount);

    let sectionTitle = '';
    let sectionSubTitle = '';
    let currentSectionNumber = 1;
    let currentQuestion = {};

    if (remainingQuestions && remainingQuestions.length) {
      currentQuestion = remainingQuestions[remainingQuestions.length - 1];
      sectionTitle = currentQuestion.sectionTitle;
      sectionSubTitle = currentQuestion.sectionSubTitle;
      currentSectionNumber = currentQuestion.sectionIndex + 1;
    }

    console.log('CURRENT STATE');
    console.log(this.state);

    const displayLoader = () => {
      return (
        <SpinnerWrapper>
          <LoadingSpinner animation="border" variant="secondary" />
        </SpinnerWrapper>
      );
    };

    const closeInterstitial = this.closeInterstitial;

    const displayCards = () => {

      return (
        <PageWrapper>
          <StudentAssessmentInterstitial
            closeInterstitial={closeInterstitial}
            currentQuestionSectionIndex={currentQuestionSectionIndex}
            assessmentComplete={assessmentComplete}
            isVisible={interstitialIsVisible}
            nextQuestionSectionIndex={nextQuestionSectionIndex}
            sectionsCount={sectionsCount}
          />
          <p>
            <strong>
              Step {currentSectionNumber}/{sectionsCount}
            </strong>
          </p>
          <SectionHeader>
            <SectionTitle>{sectionTitle}</SectionTitle>
            <SectionSubTitle>{sectionSubTitle}</SectionSubTitle>
          </SectionHeader>
          <div className="cardContainer">
            {remainingQuestions.map((question, index) => (
              <TinderCard
                ref={
                  cardRefs.find((el) => el.questionId === question.id).ref
                }
                className="swipe"
                key={question.id}
                onSwipe={(dir) => this.swiped(dir, question.id, question.key)}
                preventSwipe={['up', 'down']}
                swipeRequirementType='position'
                swipeThreshold='50'
              >
                <div className="tinderCard">
                  <div className="tinderCardInner">
                    <h3>{question.copy}</h3>
                  </div>
                </div>
              </TinderCard>
            ))}
          </div>
          <ButtonRow>
            <RoundButton 
              data-testid="tinder-card-x"
              disabled={postRequestInProgress}
              onClick={() => this.swipe('left', currentQuestion.id)}>
              <FontAwesomeIcon icon={faTimes} size="2x" />
            </RoundButton>
            <RoundButton 
              data-testid='tinder-card-check'
              disabled={postRequestInProgress}
              onClick={() => this.swipe('right', currentQuestion.id)}>
              <FontAwesomeIcon icon={faCheck} size="2x" />
            </RoundButton>
          </ButtonRow>
          <ProgressRow>
            <ProgressBar value={answersCount} max={questionsCount} />
          </ProgressRow>
        </PageWrapper>
      );
    };

    const showLoadingOrCards = () => {
      if (loading) {
        return displayLoader();
      }

      return displayCards();
    };

    return (
      <Container fluid className="p-0 h-100">
        <Row className="h-100 justify-content-lg-center">
          <Col lg={6} xs={12}>
            {showLoadingOrCards()}
          </Col>
        </Row>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { updatePercentDone };

export default connect(mapStateToProps, mapDispatchToProps)(StudentAssessment);
