import React, { useEffect, useState } from 'react';
import { Col, Form as BootstrapForm, Row } from 'react-bootstrap';
import styled from 'styled-components';
import { Form, Formik, useField } from 'formik';
import * as Yup from 'yup';
import CounselorProfileSubmitButton from './CounselorProfileSubmitButton';
import Avatar from 'react-avatar';
import ConfirmLn from '../../../common/ConfirmLn';
import { Delete24Regular as DeleteIcon } from '@fluentui/react-icons';
import { API } from '../../../../settings';
import { toast } from 'react-toastify';
import { connect, useDispatch } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import { fetchUserPic } from '../../../../features/users/userSlice';


const StyledLabel = styled.label`
  margin-top: 1rem;
  font-size: 14px;
`;

const StyledInput = styled.input`
  &.error {
    border: 1px solid red;
  }
`;

const StyledErrorMessage = styled.div`
  font-size: 14px;
  color: red;
  width: 400px;
  margin-top: 0.25rem;
`;

const StyledRow = styled(Row)`
  // padding: 25px;
`;

const ButtonWrapper = styled.div`
  padding-top: 15px;
`;

const PhotoWrapper = styled.div`
  border-radius: 100px;
  position: relative;
  background: white;
`;

const StyledAvatar = styled(Avatar)`
  font-family: Nunito;
`;

const ProfilePic = styled.img`
  object-fit: cover;
  border-radius: 100px;
`;


const MyTextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} className="form-label">
        {label}
      </StyledLabel>
      <StyledInput
        className={`text-input form-control ${
          meta.touched && meta.error ? 'error' : null
        }`}
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

const validationSchema = Yup.object({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  email: Yup.string().required('Required').email('Email is not valid'),
});

const Photo = ({ user, profilePic, size }) => {

  const style = { height: size, width: size };

  return <PhotoWrapper style={style}>
    {profilePic ? 
    <ProfilePic style={style} src={profilePic} /> :
    <StyledAvatar
      className="profileAvatar"
      round={true}
      size={size}
      name={`${user.firstName} ${user.lastName}`}
      color="#797878"
    />}
  </PhotoWrapper>
}     

const CounselorProfileForm = (props) => {

  const { firstName, lastName, email, user } = props;
  const { handleSubmit, handleInputChange } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [newProfilePic, setNewProfilePic] = useState(null);

  useEffect(() => {
    const sendFiles = async () => {
      setLoading(true);

      const file = {
        filename: newProfilePic.name,
        filetype: newProfilePic.type,
        body: await toBase64(newProfilePic),
      };
      
      try {
        await API.post(`/users/profile-pic/${user.id} `, {
          file
        });

        dispatch(fetchUserPic(user.id));
        setNewProfilePic(null);
        toast.success('Profile Pic Changed');
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setLoading(false)
      }
    }
  
    if (newProfilePic) sendFiles();
  }, [newProfilePic])


  return (
    <Formik
      enableReinitialize
      initialValues={{ firstName, lastName, email }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <div>
          <StyledRow className="justify-content-lg-center">
            <Col lg={12} xs={12}>
              {/* <ProfileForm> */}
              <Row>
                <Col xs={12}>
                  <div style={{ marginTop: 30, marginBottom: 20 }} className="horizontal-flex align-center children-mg-r">
                    <Photo size={50} user={user} profilePic={user?.profilePic} />
                    <input 
                      disabled={loading}
                      type="file"
                      id="choose_profile_picture"
                      style={{ display: 'none' }}
                      onChange={(e) => setNewProfilePic(e.target.files[0])}
                    />
                    {loading && <Spinner style={{ marginLeft: 10 }} size='sm' animation="border" />}

                    <label
                      for="choose_profile_picture"
                      className="ln-btn default"
                      style={{ margin: 0 }}
                    >
                      Change Profile Photo
                    </label>


                    <ConfirmLn
                      renderBtn={
                        <button className="ln-btn ln-icon-btn negative">
                          <DeleteIcon className="ln-icon" />
                        </button>
                      }
                      danger
                      position="right"
                      title="Are you sure you want to delete the profile picture?"
                      onOk={() => console.log()}
                      okText="Yes, Delete"
                      noText="No, Cancel"
                    />
                    {/* <Button>
                      <FontAwesomeIcon icon={faTrash} />
                    </Button> */}
                  </div>
                </Col>
              </Row>
              <BootstrapForm.Group controlId="firstName">
                <MyTextInput
                  label="First Name"
                  name="firstName"
                  placeholder=""
                  onChange={handleInputChange}
                  value={firstName}
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="lastName">
                <MyTextInput
                  label="Last Name"
                  name="lastName"
                  placeholder=""
                  onChange={handleInputChange}
                  value={lastName}
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group controlId="email">
                <MyTextInput
                  label="Contact Email Address"
                  name="email"
                  placeholder=""
                  onChange={handleInputChange}
                  value={email}
                />
              </BootstrapForm.Group>
              <BootstrapForm.Group>
                <ButtonWrapper>
                  <CounselorProfileSubmitButton />
                </ButtonWrapper>
              </BootstrapForm.Group>
              {/* </ProfileForm> */}
            </Col>
          </StyledRow>
        </div>
      )}
    </Formik>
  );
};

const mapStateToProps = (state) => {
  return{
    user: state.user
  }
}

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
});

export default connect(mapStateToProps)(CounselorProfileForm);
