import React from 'react';
import {
  ArrowSortDown20Regular as ArrowDown,
  ArrowSortUp20Regular as ArrowUp,
} from '@fluentui/react-icons';
import styled from 'styled-components';
import { Spinner } from 'react-bootstrap';

const Loader = styled(Spinner)`
  margin: 5px;
`;

export default function DashboardStatCard(props) {
  const { title, raise, percent, number, loading } = props;
  const content = () => {
    if (loading) {
      return <Loader animation="grow" size="sm" />;
    } else {
      if (percent) {
        return (
          <h6 style={{ color: raise ? '#3dd598' : '#fc5a5a' }}>
            {percent} % raise ? <ArrowUp /> : <ArrowDown />)
          </h6>
        );
      }
      return null;
    }
  };
  return (
    <div
      style={{
        width: '100%',
        boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.02)',
        height: '130px',
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
      }}
    >
      <div className="horizontal-flex align-center space-between">
        <h6 style={{ color: '#858585' }}>{title}</h6>
        {content()}
      </div>
      <h3 data-testid="total-opportunities-number">{number}</h3>
    </div>
  );
}
