import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Formik, useField, Form } from 'formik';
import DatePickerField from '../shared/DatePickerField';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import FormikSelect from '../common/FormikSelect';
import ButtonLn from '../common/Button';
import { OPEN_API } from '../../settings';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import authService from '../../services/authService';
import { connect } from 'react-redux';
import { login } from '../../features/users/userSlice';
import moment from 'moment';

const MAX_WIDTH = 576;

const GRADE_OPTIONS  = [
    { value: 6, text: '6th' },
    { value: 7, text: '7th' },
    { value: 8, text: '8th' },
    { value: 9, text: '9th' },
    { value: 10, text: '10th' },
    { value: 11, text: '11th' },
    { value: 12, text: '12th' },
    { value: 13, text: '12th+' },
];

const Wrapper = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  position: relative;
  min-height: 100vh;
  margin: 0 auto;
  max-width: ${MAX_WIDTH}px;
  overflow-y: scroll;
  overflow-x: scroll;
  width: 100%;
  &::-webkit-scrollbar {
    display: none;
  } /* Chrome, Safari and Opera */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  @media (min-width: 600px) {
    box-shadow: 1px 1px 10px rgba(0,0,0,0.5);
  }
`;

const FormikForm = styled(Form)`
  display: flex;
  flex-direction: column;
`;

const StyledLabel = styled.label`
  margin-top: 1rem;
  font-size: 14px;
`;

const StyledInput = styled.input`
  &.error {
    border: 1px solid red;
  }
`;

const A = styled.a`
  position: absolute;
  text-align: center;
  bottom: 30px;
  left: 0px;
  right: 0px;
  opacity: 0.5;
`;

const StyledErrorMessage = styled.div`
  font-size: 14px;
  color: red;
  width: 400px;
  margin-top: 0.25rem;
`;

const TextInput = (props) => {
    const [field, meta] = useField(props);

    return <StyledInput
        className={`text-input form-control ${meta.touched && meta.error ? 'error' : null
          }`}
        {...field}
        {...props}
    />
}

const Input = ({ label, children, ...props }) => {
  const [field, meta] = useField(props);

    if (!children) children = <TextInput {...props} />
    
  return (
    <>
      <StyledLabel htmlFor={props.id || props.name} className="form-label">
        {label}
      </StyledLabel>
      {children}
      {meta.touched && meta.error ? (
        <StyledErrorMessage className="error">{meta.error}</StyledErrorMessage>
      ) : null}
    </>
  );
};

function CreateAccount({ user, login }) {

  const step1 = {
    email: '',
    password: '',
  };

  const step2 = {
    firstName: '',
    lastName: '',
    dob: '',
    grade: [],
    parentEmail: ''
  };

  const [credentials, setCredentials] = useState(step1);
  const [personalData, setPersonalData] = useState(step2);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();

  console.log(credentials);

  useEffect(() => {
    if (window.location.hash === '#personal-data') setStep(2)
    else setStep(1);
  }, [location])

  const query = new URLSearchParams(useLocation().search.slice(1));
  const schoolId = query.get('schoolId');

  const isMinor = (date) => {
    return moment().diff(moment(date), 'years') < 18
  };

  const step1Schema = Yup.object({
    email: Yup.string().email().required('Required'),
    password: Yup.string().min(8).required('Required'),
  });

  const step2Schema = Yup.object({
    parentEmail: Yup.string().email().notRequired(),
    firstName: Yup.string().required('Required'),
    lastName: Yup.string().required('Required'),
    dob: Yup.string().required('Required'),
    grade: Yup.array().min(1, 'Select your Grade.').required('Required'),
  });

  const handleStep1 = async (values) => {
    try {
      setLoading(true);
      await OPEN_API.post(`/email`, { email: values.email });
      setCredentials(values);
      window.location.hash = '#personal-data'
    } catch (err) {
      toast.error("Email already in use. Please use another one.");
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      if (!credentials.email) history.replace('/student/create-account?schoolId=' + schoolId);
      const filtered = Object.assign({}, values);
      filtered.grade = values.grade[0];
      const response = await OPEN_API.post(`/create-student/${schoolId}`, {...credentials, ...filtered});
      console.log(JSON.stringify(response.data));
      await authService.assignSession(response.data);
      login(response.data);
    } catch (err) {
      console.log(err);
      toast.error('Something wrong happened, please try later.');
    } finally {
      setLoading(false);
    }
  }

  if (user.isLoggedIn && user.userType !== 'STUDENT') return <Redirect to='/' />;

  if (user.isLoggedIn) return <Redirect to='/dashboard' />;

  const Button = () => (
    <ButtonLn
      loading={loading}
      type="submit"
      content={step === 1 ? "Next" : "Create Account"}
      className="ln-btn primary"
      style={{ alignSelf: 'flex-end', marginTop: 30 }}
    />
  );

  const Step2 = () => (
    <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={personalData}
        onSubmit={handleSubmit}
        validationSchema={step2Schema}
      >
      {props => (<FormikForm>
        <Input 
          name="firstName"  
          label="First Name" 
          placeholder="Your first name"
          onChange={props.handleChange}
        />
        <Input 
          name="lastName" 
          label="Last Name" 
          placeholder="Your last name"
          onChange={props.handleChange}
        />
        <Input name="grade" label="Your Grade">
          <FormikSelect options={GRADE_OPTIONS} name="grade"/>
        </Input>
        <Input name="dob" label="Date of Birth">
          <DatePickerField
          name="dob"/>
        </Input>
        <Input 
          name="parentEmail" 
          label="Parent Email" 
          placeholder="Your Parent's Email"
        />
        <Button />
        </FormikForm>
      )}
    </Formik>
  );

  const Step1 = () => (
    <Formik
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={credentials}
      onSubmit={handleStep1}
      validationSchema={step1Schema}
    >
    {props => (<FormikForm>
      <Input 
        name="email" 
        label="Email" 
        placeholder="Your email"
      />
      <Input 
        name="password" 
        type="password"
        label="Password (8 character minimum)" 
        placeholder="•••••••••"
      />
      <Button />
      </FormikForm>
    )}
  </Formik>
  )

  return <Wrapper>
    <h1>Create your account</h1>
    <p><b>Welcome!</b> Please fill the form below to create your student account.</p>
    {step === 1 ? <Step1 /> : <Step2 />}
    <A href='/sign-in'>Already have an account?</A>
  </Wrapper>

}

const mapStateToProps = (state) => ({ user: state.user });
const mapDispatchToProps = { login };

export default connect(mapStateToProps, mapDispatchToProps)(CreateAccount);