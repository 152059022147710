import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API } from '../../settings';
import { 
  SIGN_IN, 
  identify,
  track, 
  trackWithRole,
  SIGN_OUT,
  reset} from '../../utils/mixpanel';


export const fetchUserPic = createAsyncThunk(
  'user/fetchUserPic',
  async (userId, thunkAPI) => {
    const result = await API.get(`/users/profile-pic/${userId}`);
    return result.data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState: {
    accessToken: '',
    email: '',
    firstName: '',
    id: null,
    isLoggedIn: false,
    lastName: '',
    toastMessage: '',
    toastStatus: 'none',
    userType: '',
    uuid: '',
    userTypeProfile: {},
    accepted_terms: false,
    accepted_privacy: true,
    profilePic: null,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserPic.fulfilled, (state, action) => {
      state.profilePic = action.payload;
      return state;
    });
  },
  reducers: {
    login(state, action) {
      const {
        accessToken,
        email,
        firstName,
        id,
        lastName,
        toastMessage,
        toastStatus,
        userType,
        uuid,
        userTypeProfile,
        accepted_privacy,
        accepted_terms,
      } = action.payload;
      state.uuid = uuid;
      state.accessToken = accessToken;
      state.email = email;
      state.firstName = firstName;
      state.id = id;
      state.isLoggedIn = true;
      state.lastName = lastName;
      state.userType = userType;
      state.userTypeProfile = userTypeProfile;
      state.accepted_terms = accepted_terms;
      state.accepted_privacy = accepted_privacy;
      identify(action.payload);
      track(SIGN_IN);
      trackWithRole(SIGN_IN, action.payload);


      if (toastMessage) {
        state.toastMessage = toastMessage;
      }

      if (toastStatus) {
        state.toastStatus = toastStatus;
      }
    },
    logout(state, action) {
      
      track(SIGN_OUT);
      reset();

      state.accessToken = '';
      state.email = '';
      state.firstName = '';
      state.id = null;
      state.isLoggedIn = false;
      state.lastName = '';
      state.toastMessage = '';
      state.toastStatus = 'none';
      state.userType = '';
      state.uuid = '';
      state.userTypeProfile = {};
      state.accepted_terms = false;
      state.accepted_privacy = false;

    
    },
    setToast(state, action) {
      const { toastMessage, toastStatus } = action.payload;

      state.toastMessage = toastMessage;
      state.toastStatus = toastStatus;
    },
    clearToast(state) {
      state.toastMessage = '';
      state.toastStatus = 'none';
    },
    updatePercentDone(state, action) {
      const { percentDone } = action.payload;

      state.percentDone = percentDone;
    },
    updateStudent(state, action) {
      const { firstName, lastName } = action.payload;
      state.firstName = firstName;
      state.lastName = lastName;
    },
    updateUser(state, action) {
      return Object.assign({}, state, action.payload);
    },
  },
});

export const {
  clearToast,
  login,
  logout,
  setToast,
  updatePercentDone,
  updateStudent,
  updateUser,
} = userSlice.actions;

export default userSlice.reducer;
