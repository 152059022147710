import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { API } from '../settings';
import { connect } from 'react-redux';
import { updateUser } from '../features/users/userSlice';

const PageWrapper = styled.div`
  padding: 13px 25px 25px;
`;

const Title = styled.h6`
  text-align: center;
`;

const Check = styled.label`
  display: flex;
  align-items: center;
  margin: 0px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: 800;
  justify-content: center;
  border: 1px solid rgba(0,0,0,0.1);
  border-radius: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const Input = styled.input`
  margin-left: 10px;
  height: 12px;
`;

const Terms = ({ user, updateUser }) => {

    const history = useHistory();
    const location = useLocation();
    const query = new URLSearchParams(location.search)
    const enabled = query.get('enabled');

    const [loading, setLoading] = useState();
    
    const handleSubmit = async () => {
        try {
            await API.put(`/users/${user.uuid}/accept-terms`, { accepted_terms: true });
            updateUser({ accepted_terms: true });
            history.replace('/accept-terms-privacy');
        } catch (err) {
            setLoading(false)
        }
    }

    const onChange = () => {
        if (loading) return;
        setLoading(true)
        setTimeout(handleSubmit, 200)
    }

    return (
    <PageWrapper>
        <Title>Launch Now</Title>
        <Title>Terms of Use</Title>
        <p><a href="http://www.launchnow.org/">These Terms of Use (these “Terms of Use”) set forth the mutual agreement between Launch Now, LLC, a New Hampshire LLC (“Launch Now”, “we”, “us”, or “our”), and you as to your rights and responsibilities when you access and use Launch Now’s website,</a> www.launchnow.org, any of Launch Now’s applications, and all online services provided by Launch Now (collectively, the “Online Services”). Your access to and use of the Online Services and the information, materials, products, and services available through the Online Services are subject to these Terms of Use, regardless of whether you possess an account through the Online Services linked to your name and/or contact information. By accessing or using the Online Services, you acknowledge that you have read, understand and agree to be bound by these Terms of Use, and you affirm that you are at least 18 years of age or possess legal parental or guardian consent and are fully able and competent to enter into these Terms of Use. IF YOU ARE UNDER 18 YEARS OF AGE, PLEASE BE SURE TO READ THESE TERMS OF USE WITH YOUR PARENTS OR GUARDIAN, AND YOUR PARENT OR GUARDIAN MUST AGREE TO THESE</p>
        <p>TERMS OF USE ON YOUR BEHALF. If you do not understand or agree to be bound by these Terms of Use, do not access or use the Online Services.</p>
        <ol>
        <li>
        <h6>CHANGES TO THESE TERMS OF USE</h6>
        <p>Launch Now reserves the right, at its sole discretion, to modify, change, add or remove portions of these Terms of Use, in whole or in part, at any time. Modifications shall be effective immediately upon the upload and publishing of the modified Terms of Use to the Online Services. You agree to comply with, and be bound by, any such modifications either by continuing to use or access the Online Services after modified Terms of Use are published to the Online Services.</p>
        </li>
        <li>
        <h6>PRIVACY POLICY</h6>
        <p>Our privacy policy (“Privacy Policy”) is a part of these Terms of Use and is incorporated by reference. By accepting these Terms of Use you agree to our collection, use, and disclosure of your information as described in the Privacy Policy.</p>
        </li>
        <li>
        <h6>ACCOUNTS</h6>
        <p>In order to access certain features of the Online Services, you will have to create an account by providing your name, email address and by creating a password. In order to set up an account to access Launch Now’s Student Portal, you will also need to provide your date of birth, grade, and a parent or guardian’s email address.</p>
        <p>Before a student under the age of 18 is allowed to set up an account, we will seek the consent of the student’s parent or legal guardian via email. This consent may be provided by the parent or guardian online or the parent or guardian may provide a hard copy consent to the student’s school. If a hard copy consent is provided, the school administrator will provide online consent on the parent’s or guardian’s behalf. In the email requesting consent, we will explain what information we are collecting, how we plan to use it, how the parent or guardian can provide consent, and how the parent or guardian can revoke consent. If we do not receive consent within a reasonable time, we will delete the parent contact information and any information regarding the student.</p>
        </li>
        <li>
        <h6>SUBSCRIPTIONS; PAYMENT</h6>
        <p>Community Partners may purchase an annual subscription to the Online Services that may include several additional features or services. Payments for subscriptions are collected by Launch Now’s third-party payment processors, and you authorize us to receive payments for the Online Services via such third-party payment processors, using the payment information you have supplied. These subscriptions</p>
        <p><a href="mailto:info@launchnow.org">will automatically renew until they are cancelled, at which point the subscription will continue until the next billing cycle begins. A subscription may be cancelled at any time; however, all fees paid for such subscription are non-refundable. The price at which the subscription is offered may vary due to date of subscription, country, local taxes and regulations, promotions, and Launch Now reserves the right to change the price and subscription benefits at its sole discretion. If you have issues with our subscription or would like to request a refund, please email</a> info@launchnow.org. We reserve the right to offer or refuse refunds at our sole discretion. Subscriptions may be suspended or terminated for overdue payments. Launch Now reserves the right to offer or refuse subscriptions for any reason at our sole discretion.</p>
        </li>
        <li>
        <h6>INTELLECTUAL PROPERTY; LIMITED LICENSE</h6>
        <p>Subject to these Terms of Use, users of the Online Services (“Users”) are granted a limited, personal, non-exclusive, non-transferable, revocable license to access and make personal use of the Online Services. This license is personal to you and may not be assigned or sublicensed to anyone. This license does not include any resale or commercial use of the Online Services or its Content (as defined below), any derivative use of the Online Services or its Contents, or any use of data mining, robots, or similar data gathering and extraction tools. You agree that you will not reproduce, redistribute, duplicate, copy, sell, transfer, create derivative works from, decompile, reverse engineer, alter, adapt, disassemble, or otherwise exploit the Online Services or any source code therein. You further agree that you will not remove any copyright, trademark or other proprietary rights notices contained in or displayed on any portion of the Online Services. All rights not expressly granted by Launch Now herein are reserved. All software included in the Online Services, and all copyrights thereto, is the property of Launch Now or its licensors.</p>
        <p>As used throughout these Terms of Use, “Content” means all text, graphics, user interfaces, photographs, videos, trademarks, trade names, service marks, logos, sounds, music, artwork, computer code, and other materials in all forms and mediums. Content contained on the Online Services (collectively, “Launch Now Content”) is owned, controlled, or licensed by or to Launch Now, and is protected by trade dress, copyright, patent, and trademark laws, and various other intellectual property rights and laws.</p>
        <p>No license to or right in any such Content is granted to or conferred upon you. Except as expressly provided in these Terms of Use, you agree not to use, modify, copy, reproduce, republish, upload, post, transmit, distribute, sell, license, rent, publicly display or perform, edit, adapt or create a</p>
        <p>derivative work of, in any manner, any Launch Now Content or any other part of the Online Services without Launch Now’s express prior written consent. Notwithstanding the foregoing, you may view, use, download, and print selected portions of the Online Services solely for your own personal, non-commercial, informational use, provided that you do not republish the Content and that you keep intact all copyright, trademarks, trade names, service marks, attributions, patent, and other proprietary notices.</p>
        </li>
        <li>
        <h6>YOUR PROVISION OF INFORMATION</h6>
        <p>When you provide information about yourself to us or to other Users, including when you create an account, you agree to: (a) provide accurate and current information, and not to provide information that attempts to impersonate another individual; and (b) maintain and promptly update such information to keep it accurate and current. If you provide any information about yourself that is untrue or inaccurate, or we have reasonable grounds to suspect that such information is untrue or inaccurate, Launch Now retains the right, at its sole discretion, to suspend or terminate any account you establish, decline to provide you with services, and/or to refuse any or all current or future use of the Online Services or any portion thereof.</p>
        <p>For each piece of Content that you submit through the Online Services, including information about</p>
        <p>yourself or another person, you represent and warrant that: (a) you have the authority to provide that Content and information; (b) the Content does not infringe any third party’s rights, including privacy rights and intellectual property rights; (c) you own or have the necessary licenses, rights, consents, and permissions to publish such Content; and (d) the Content complies with these Terms and all applicable laws.</p>
        <p>You acknowledge and agree as follows: (a) Launch Now does not permit copyright infringement or other violations of intellectual property rights or privacy rights on the Online Services, and we reserve the right to remove Content if properly notified that such Content infringes on another’s intellectual property rights or privacy rights; (b) Launch Now is not responsible or liable for the accuracy, utility, safety, or intellectual property rights of or relating to any Content within the Online Services; (c) Launch Now is not liable for any Content or for the defamatory, offensive, or illegal conduct of any third party, and the risk of harm or damage from the foregoing rests entirely with you; and (d) we do not guarantee the confidentiality of any Content you submit, and you are solely responsible for your own Content and the consequences of submitting or publishing your Content on the Online Services.</p>
        </li>
        <li>
        <h6>LAUNCH NOW’S DISCRETION TO USE USER-PROVIDED CONTENT</h6>
        <p>All Content that you upload, post, transmit, publish, display, or otherwise make available through the Online Services (“User-Provided Content”) may be used by Launch Now in accordance with our Privacy Policy. Launch Now reserves the right to change, condense, delete, or refuse to post any User-Provided Content on the Online Services in its sole discretion. None of the User-Provided Content that you submit shall be sold or disclosed on the part of Launch Now, its agents, partners, or third-party service providers and their respective directors, officers, and employees in accordance with state and local data privacy laws.</p>
        </li>
        <li>
        <h6>YOUR CONDUCT</h6>
        <p>You agree to comply with all laws, rules, and regulations applicable to your access to and use of the Online Services. In addition, when accessing or using the Online Services, you agree not to:</p>
        <ul>
            <li>
            <p>defame, harass, stalk, threaten or otherwise violate the rights of others, including, without limitation, others’ privacy or publicity rights;</p>
            </li>
            <li>
            <p>impersonate any person or entity or use fraudulent, misleading or inaccurate email address or other contact information;</p>
            </li>
            <li>
            <p>harm or exploit minors;</p>
            </li>
            <li>
            <p>upload, post, transmit, publish, display, or otherwise make available through the Online Services any User-Provided Content that: (a) is known by you to be false, inaccurate, or misleading; (b) violates any federal, state, or local law, statute, ordinance, or regulation (including, but not limited to, those governing export control, consumer protection, unfair competition, anti-discrimination, or false advertising);</p>
            <p>(c) is, or may reasonably be considered to be, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically or otherwise objectionable, or that harms minors in any way; (d) you do not have a right to make available under any law or under contractual or fiduciary; or (e) infringes any patent, trademark, trade secret, copyright, right of publicity, or other right of any party;</p>
            </li>
            <li>
            <p>forge headers or otherwise manipulate identifiers in order to disguise the origin of any User-Provided Content transmitted through the Online Services;</p>
            </li>
            <li>
            <p>upload, post, transmit, publish, display, or otherwise make available any unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation;</p>
            </li>
            <li>
            <p>upload, post, transmit, publish, display, or otherwise make available User-Provided Content provided by another User of the Online Services (or an image or picture depicting such User-Provided Content) on any other website, mobile application, or online service without that User’s prior permission;</p>
            </li>
            <li>
            <p>upload, post, transmit, publish, display, or otherwise make available any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment;</p>
            </li>
            <li>
            <p>take any action that interferes with the proper working of the Online Services, compromises the security of the Online Services, or otherwise damages the Online Services or any materials and information available through the Online Services;</p>
            </li>
            <li>
            <p>attempt to gain unauthorized access to any portion or feature of the Online Services, to any other systems or networks connected to the Online Services, to any of our servers, or to any of the services offered on or through the Online Services, including but not limited to by hacking, password “mining”, or any other unauthorized means;</p>
            </li>
            <li>
            <p>probe, scan, or test the vulnerability of the Online Services or any network connected to the Online Services or bypass the authentication measures on the Online Services or any network connected to the Online Services;</p>
            </li>
            <li>
            <p>use any automated means to collect information or content from or otherwise access the Online Services, including, but not limited to, through the use of technical tools known as robots, spiders, or scrapers, without our prior permission;</p>
            </li>
            <li>
            <p>harvest or otherwise collect and store information about Users of the Online Services, including e-mail addresses;</p>
            </li>
            <li>
            <p>install any software, file, or code that is not authorized by the User of a computer or device or that assumes control of all or any part of the processing performed by a computer or device without the authorization of the User of the computer or device; or</p>
            </li>
            <li>
            <p>interfere with or disrupt the operation of the Online Services or server networks connected to the Online Services, or disobey any requirements, procedures, policies, or regulations of networks connected to the Online Services.</p>
            <p>Launch Now has the right, but not the obligation, to monitor all conduct on and Content submitted to the Online Services. Failure to comply with any of the above conduct requirements will constitute a violation of these Terms of Use and, in addition to any other rights or remedies we may have, Launch Now may immediately terminate your access to and use of the Online Services or remove</p>
            <p>any Content that you submit, upload or display.</p>
            </li>
        </ul>
        </li>
        <li>
        <h6>TERMINATION; ACCOUNT DELETION</h6>
        <p>Launch Now may terminate, suspend or disable your access and use of the Online Services (or any part thereof), delete your account, or block or remove any Content you submit if Launch Now determines, in its sole discretion, that you have violated any provision of these Terms of Use.</p>
        <p><a href="mailto:info@launchnow.org">Launch Now reserves the right to determine whether Content violates these Terms of Use. If Launch Now deletes your account for any of the reasons set forth herein, you may not re-register for the Online Services. Launch Now may block your email address and Internet protocol address to prevent further registration. If you believe that Launch Now has suspended or terminated your account in error, you may contact us at</a> info@launchnow.org at any time.</p>
        <p>Upon termination, all licenses granted by Launch Now will terminate automatically. Sections 2, 5, 6, 7, 9, 10, and 14 through 18 of these Terms of Use shall survive termination. In the event of an account deletion for any reason, Content that you submitted may no longer be available. Launch Now is not responsible for the loss of such Content.</p>
        </li>
        <li>
        <h6>LINKS TO OTHER WEBSITES AND ONLINE SERVICES</h6>
        <p>The Online Services may contain links to or at times redirect you to other websites and online services owned or controlled by third parties (collectively, “Linked Sites”). Also, at your request, the Online Services may connect to social networking websites that are not owned or controlled by Launch Now.</p>
        <p>These Linked Sites are not under the control of Launch Now, and you acknowledge and agree that in no way will Launch Now be responsible or liable for the accuracy; collection, use, or disclosure of information; copyright compliance; legality; decency; or any other aspect of such Linked Sites including their operations, or the content displayed on or through them. The inclusion of such a link on the Online Services does not imply our endorsement of any such website or online service, the content displayed on or through it, or any association with its operators. By using the Online Services, you expressly release Launch Now, its principals, employees, and agents from any and all liability arising from your access to or use of any Linked Sites.</p>
        </li>
        <li>
        <h6>INFORMATION, NEWS, AND PRESS RELEASES</h6>
        <p>The Online Services may contain information about Launch Now, such as news and press releases. You acknowledge that Launch Now has no duty or obligation to maintain the accuracy of, or update any such information, and agree that your reliance on any such information is at your own risk.</p>
        </li>
        <li>
        <h6>REGISTRATION AND PASSWORDS</h6>
        <p>You acknowledge and agree that you are solely responsible for maintaining the confidentiality of your account login information and for all uses of your account, whether authorized by you or not. You agree to notify us immediately of any unauthorized use of account or any other breach of security involving access to the Online Services through your account. You acknowledge that you may be held liable for any loss or harm incurred by us or any other person or entity due to someone else using account login information as a result of your failing to keep your account information secure and confidential.</p>
        </li>
        <li>
        <h6>OPERATION OF THE ONLINE SERVICES</h6>
        <p>We reserve the right to do any of the following, at any time, at our sole discretion, with or without notice: (i) modify, suspend, or terminate operation of or your access to the Online Services, or any</p>
        <p>portion of the Online Services, including but not limited to for your violation of these Terms of Use;</p>
        <p>(ii) modify or change the Online Services, or any portion of the Online Services; and (iii) interrupt the regular operation of the Online Services, or any portion of the Online Services, as necessary to perform routine or non-routine maintenance, to correct errors, or to make other changes to the Online Services.</p>
        </li>
        <li>
        <h6>DISCLAIMER OF WARRANTIES</h6>
        <p>YOU USE THE ONLINE SERVICES VOLUNTARILY AND AT YOUR OWN RISK. THE ONLINE SERVICES AND ALL CONTENT AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE ONLINE SERVICES ARE PROVIDED "AS IS" AND ON AN "AS AVAILABLE" BASIS. TO THE FULLEST EXTENT PERMITTED BY LAW, LAUNCH NOW EXPRESSLY DISCLAIMS ANY AND ALL WARRANTIES OF ANY KIND, EITHER EXPRESS OR IMPLIED, IN CONNECTION WITH THE ONLINE SERVICES AND YOUR USE THEREOF, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NONINFRINGEMENT.</p>
        <p>WITHOUT LIMITING THE FOREGOING, LAUNCH NOW DOES NOT WARRANT THAT THE MATERIALS, INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE ONLINE SERVICES ARE ACCURATE, RELIABLE, OR CORRECT WITHOUT DEFECTS OR ERRORS; THAT THE ONLINE SERVICES WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE ONLINE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS. YOUR USE OF THE ONLINE SERVICES IS AT YOUR SOLE RISK AND YOU ASSUME TOTAL RESPONSIBILITY FOR YOUR USE OF THE ONLINE SERVICES AND ANY LINKED SITES.</p>
        <p>YOUR SOLE REMEDY AGAINST LAUNCH NOW FOR DISSATISFACTION WITH THE ONLINE SERVICES OR ANY CONTENT THEREIN IS TO STOP USING THE ONLINE SERVICES. THIS LIMITATION OF RELIEF IS A PART OF THE BARGAIN BETWEEN THE PARTIES FOR THE USE AND ENJOYMENT OF THE ONLINE SERVICES. BECAUSE SOME JURISDICTIONS DO NOT PERMIT THE EXCLUSION OF CERTAIN WARRANTIES, THESE EXCLUSIONS MAY NOT APPLY TO YOU.</p>
        <p>Launch Now does not endorse, guarantee or assume responsibility for any Content, product or service posted, advertised or offered by any third party on or through the Online Services, and Launch Now will not be a party to, or be responsible for monitoring, any transaction between you and any third-party, including other Users.</p>
        </li>
        <li>
        <h6>LIMITATION OF LIABILITY</h6>
        <p>UNDER NO CIRCUMSTANCES SHALL LAUNCH NOW BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED WITH (A) THE USE OF, OR INABILITY TO USE, THE ONLINE SERVICES, (B) TANGIBLE OR INTANBIGLE PROPERTY DAMAGE OR LOSS OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF THE ONLINE SERVICES, (C) ERRORS, OMISSIONS OR INACCURACIES OF CONTENT, (D) ANY UNAUTHORIZED ACCESS TO OR USE OF THE ONLINE SERVICES OR LAUNCH NOW’S SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED ON SUCH SERVERS,</p>
        <p>(E) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE ONLINE SERVICES, (F) ANY BUGS, VIRUSES, TROJAN HORSES OR THE LIKE WHICH MAY BE TRANSMITTED TO, BY OR THROUGH THE ONLINE SERVICES, (G) ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF YOUR USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED OR OTHERWISE MADE AVAILABLE VIA THE ONLINE SERVICES.</p>
        <p>THIS LIMITATION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR OTHER LEGAL THEORY, EVEN IF LAUNCH NOW HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. IN THE EVENT SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF DAMAGES TO THE EXTENT INDICATED ABOVE, OUR LIABILITY IN SUCH JURISDICTIONS SHALL BE LIMITED TO THE EXTENT PERMITTED BY LAW.</p>
        </li>
        <li>
        <h6>INDEMNIFICATION</h6>
        <p>To the fullest extent permitted by applicable law, you agree to defend, indemnify, and hold Launch Now, our affiliates, joint ventures, third-party service providers, and our respective employees, contractors, agents, officers, and managers harmless from all liabilities, claims, damages, losses, costs and expenses (including reasonable attorneys' fees) that arise out of or are related to: (a) your access to or use of the Online Services, (b) any User-Provided Content you upload, post, transmit, publish, display, or otherwise make available through the Online Services, (c) your violation of these Terms of Use, (d) your use or misuse of the Online Services, (e) your violation of any third-party rights, including without limitation any intellectual property right or privacy right, or (f) your interaction with any other User.</p>
        </li>
        <li>
        <h6>EQUITABLE RELIEF</h6>
        <p>You acknowledge that any breach or threatened breach of these Terms of Use will result in irreparable harm to Launch Now for which damages would not be an adequate remedy, and, therefore, in addition to our rights and remedies otherwise available at law, Launch Now shall be entitled to immediate equitable relief, including injunctive relief, as appropriate. If Launch Now seeks any equitable remedies, Launch Now shall not be precluded or prevented from seeking remedies at law, nor shall Launch Now be deemed to have made an election of remedies.</p>
        </li>
        <li>
        <h6>GENERAL</h6>
            <p><b>Severability.</b> If any provision of these Terms of Use is held unenforceable or invalid under any applicable law or is so held by an applicable court decision, such unenforceability or invalidity will not render these Terms of Use unenforceable or invalid as a whole, and such provision will be changed and interpreted so as to best accomplish the objectives of such unenforceable or invalid provision within the limits of applicable law or the applicable court decisions.</p>
            <p><b>Waiver.</b> Any waiver by Launch Now of a breach of any provision of these Terms of Use shall not operate as or be construed to be a waiver of any other breach of such provision or of any breach of any other provision of these Terms of Use. Any such waiver must be in writing. Failure by Launch Now to insist upon strict adherence to any term of these Terms of Use on one or more occasions shall not be considered a waiver or deprive Launch Now of the right to insist upon strict adherence to that term or any other term of these Terms of Use in the future.</p>
            <p><b>Governing Law.</b> You agree that all matters relating to your access to or use of the Online Services will be governed by the laws of the United States and by the laws of the State of New Hampshire without regard to its conflicts of laws provisions. You agree to the personal jurisdiction by and venue in the state and federal courts in Rockingham County, New Hampshire, and waive any objection to such jurisdiction or venue.</p>
            <p><b>Contact Us.</b> <a href="mailto:info@launchnow.org">If you have any questions regarding these Terms of Use, please contact us at:</a> <a href="mailto:info@launchnow.org">info@launchnow.org</a><a href="mailto:info@launchnow.org">.</a></p>
        </li>
        </ol>
        <h6>Effective Date: September 28, 2022</h6>
        {enabled && <Check style={loading ? { opacity: 0.5 } : {}}>
            I accept these terms
            <Input onChange={onChange} 
              type='checkbox' 
              data-testid="termsCheckbox"
            />
        </Check>}
    </PageWrapper>
  );
};

const mapStateToProps = (state) => ({ user: state.user });

const mapDispatchToProps = { updateUser };

export default connect(mapStateToProps, mapDispatchToProps)(Terms);
