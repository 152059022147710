import React from 'react';
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import XLSX from 'xlsx';
import styled from 'styled-components';

import downloadIcon from '../../images/cluster-icons/017-download.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API } from '../../settings';
import { validateEmail, validateXlsDate } from '../../utils/validators';
import { BULK_CREATE_STUDENT, track } from '../../utils/mixpanel';

const CSV_EXAMPLE =
  'https://launch-now-assets.s3.amazonaws.com/demo-data/example-student-data.csv';
const XLS_EXAMPLE =
  'https://launch-now-assets.s3.amazonaws.com/demo-data/example-student-data.xlsx';

const Button = styled.button`
  padding: 10px 15px;
  border-radius: 12px;
  background-color: rgba(0, 0, 0, 0.1);
  color: black;
  border: none;
  font-weight: bold;
  font-size: 12px;
  margin-left: 15px;
`;

const HeaderText = styled.h3`
  flex: 1;
`;

const PageHeader = styled.div`
  margin-top: 6px;
  display: flex;
  align-items: center;
`;

const ButtonIcon = styled.img`
  margin-right: 10px;
`;

const ResultList = styled.ul`
  padding: 0px;
  margin: 0px;
`;

const Result = styled.li`
  list-style-type: none;
`;

const SkippedList = styled(ResultList)`
  margin-bottom: 10px;
  margin-top: 5px;
  padding-top: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
`;

const PageBlock = styled.div`
  margin-top: 50px;
  padding: 50px 46px;
  border-radius: 30px;
  box-shadow: 0 8px 25px 0 rgba(0, 0, 0, 0.05);
  background-color: #ffffff;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

const FIELDS = [
  'First Name',
  'Last Name',
  'Student Email',
  'Parent Email',
  'Date of Birth',
  'Grade',
  'Approved by Counselor',
];

const DownloadButton = ({ children, onClick, url }) => (
  <a href={url} target="_blank" rel="noreferrer" download>
    <Button onClick={onClick}>
      <ButtonIcon src={downloadIcon} alt={children} />
      {children}
    </Button>
  </a>
);

export default class UploadStudents extends React.Component {
  constructor(props) {
    super(props);

    this.state = { data: null, loading: false, results: {} };
    this.importXls = this.importXls.bind(this);
    this.importCsv = this.importCsv.bind(this);
    this.uploadData = this.uploadData.bind(this);
    this.onDataUpload = this.onDataUpload.bind(this);
    this.isDataValid = this.isDataValid.bind(this);
    this.onError = this.onError.bind(this);
  }

  validateStudentField = (student, errors) => (validator, index) => {
    if (!validator(student[index]))
      errors.push(`Invalid field '${FIELDS[index]}'`);
  };

  validateParentEmail = (student, errors) => {
    if (!!(validateEmail(student[3]) || ['yes', 'no'].includes(student[6])))
      return;
    else errors.push('Invalid Parent email or Approved by counselor');
  };

  cleanContent = (rows) => {
    return rows
      .filter((row) => {
        //clean rows
        const isStudentRow = row.findIndex((field) => !!field) <= 7;
        return isStudentRow;
      })
      .map((row) => {
        //clean columns
        return row.slice(0, 7);
      });
  };

  validateStudent = (student, row) => {
    let errors = [];

    console.log('validating student');
    console.log('student', student);
    console.log('row', row);

    const emptyIndex = student.findIndex(
      (field, i) => !field && ![3, 6].includes(i)
    ); //skip these
    if (emptyIndex >= 0) errors.push(`'${FIELDS[emptyIndex]}' is empty`);

    //validate specifics

    const validator = this.validateStudentField(student, errors);
    validator(validateEmail, 2);
    validator(validateXlsDate, 4);
    validator((s) => !isNaN(s), 5);
    this.validateParentEmail(student, errors);

    return errors.length ? errors[0] + ` on row ${row + 1}.` : null;
  };

  onError = (errorMessage) => {
    this.setState({
      loading: false,
    });

    toast.error(errorMessage, {
      position: 'top-center',
      autoClose: 30000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  isDataValid = (data) => {
    // Keep only valid rows (rows that have a non-empty first column)
    const validData = data.filter((row, index) => {
      if (index === 0) return true; // Keep the header row
      return row[0].trim() !== ''; // Ensure column 0 is not empty
    });

    const errors = validData
      .slice(1) // Skip the header row
      .map(this.validateStudent)
      .reduce((prev, next) => (next ? [...prev, next] : prev), []);

    if (errors.length > 0) {
      this.onError(errors[0] + ' Please check again.');
      return false;
    }
    return true;
  };

  parseCsv = (e) => {
    const text = e.target.result;
    const lineSeparator = text.indexOf('\r') >= 0 ? '\r\n' : '\n';
    const rows = e.target.result.split(lineSeparator);
    let data = rows.map((row) => row.split(','));

    // Stop parsing further rows if the first column is empty
    data = data.reduce((acc, row, index) => {
      const trimmedRow = row.map((item) => item.trim());
      if (trimmedRow[0] === '') {
        // Stop parsing further rows
        return acc;
      }
      acc.push(trimmedRow);
      return acc;
    }, []);

    data = this.cleanContent(data);
    return this.isDataValid(data) ? data : null;
  };

  parseXls = (e, rABS) => {
    const bstr = e.target.result;
    const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
    /* Get first worksheet */
    const wsname = wb.SheetNames[0];
    const ws = wb.Sheets[wsname];
    /* Convert array of arrays */
    let data = XLSX.utils.sheet_to_json(ws, { header: 1, raw: false });
    data = data.filter((d) => !!d.length);
    data = this.cleanContent(data);
    return this.isDataValid(data) ? data : null;
  };

  onDataUpload = (response, data) => {
    this.setState({
      data: data,
      loading: false,
      results: response.data,
    });

    track(BULK_CREATE_STUDENT);

    return response.data;
  };

  uploadData = (data) => {
    API.post(`/students/import`, {
      students: data,
    })
      .then((response) => this.onDataUpload(response, data))
      .catch(() => this.onError('There was an error'));
  };

  importCsv(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      this.setState({ loading: true });
      const data = this.parseCsv(e);
      if (data) this.uploadData(data, []);
    };
    reader.readAsText(file);
  }

  importXls(file) {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;

    reader.onload = (e) => {
      this.setState({ loading: true });
      const data = this.parseXls(e, rABS);
      if (data) this.uploadData(data, []);
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  }

  render() {
    const { loading } = this.state;

    const tempRenderUploaded = () => {
      if (loading) {
        return (
          <span>
            <LoadingSpinner animation="border" variant="secondary" />
          </span>
        );
      } else if (this.state.data) {
        return (
          <>
            <ResultList>
              <Result>
                <b>
                  Data uploaded. All set.{' '}
                  <span aria-label="thumbs up" role="img">
                    👍
                  </span>
                </b>
              </Result>
              <Result>
                <b>{this.state.results?.created} created.</b>
              </Result>
              <Result>
                <b>{this.state.results?.updated} updated.</b>
              </Result>
            </ResultList>
            {this.state.results?.skipped && (
              <SkippedList>
                {this.state.results?.skipped.map((skipped, i) => (
                  <Result key={i}>
                    Skipped: {skipped.firstName} {skipped.lastName}
                  </Result>
                ))}
              </SkippedList>
            )}
          </>
        );
      } else {
        return (
          <span>
            Drag 'n drop an Excel or CSV file here, or click to select from your
            computer.
          </span>
        );
      }
    };

    const sectionStyle = {
      width: '100%',
      minHeight: '257px',
      textAlign: 'center',
      padding: '25px 0 0 0',
      margin: 0,
      borderRadius: '15px',
      border: 'solid 1px #c6c6c6',
    };

    const onDropToDropzone = (acceptedFiles) => {
      const test = (regex) => (file) => regex.test(file.name);
      const file = acceptedFiles[0];
      if (test(/.*\.csv/)(file)) this.importCsv(file);
      else if (test(/.*\.xls/)(file)) this.importXls(file);
    };

    return (
      <Container className="p-0 h-100">
        <Row>
          <Col lg={12}>
            <PageHeader>
              <HeaderText>Upload Students</HeaderText>
              <DownloadButton url={CSV_EXAMPLE}>
                Download CSV Template
              </DownloadButton>
              <DownloadButton url={XLS_EXAMPLE}>
                Download XLS Template
              </DownloadButton>
            </PageHeader>
            <PageBlock>
              <Dropzone onDrop={onDropToDropzone}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <p style={sectionStyle}>{tempRenderUploaded()}</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </PageBlock>
          </Col>
        </Row>
      </Container>
    );
  }
}
