import React from 'react';
import { connect } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import StudentAssessmentCta from './StudentAssessmentCta';
import StudentDashboardAllClusters from './StudentDashboardAllClusters';
import StudentDashboardRankedClusters from './StudentDashboardRankedClusters';
import StudentDashboardSubcategoriesList from './StudentDashboardSubcategoriesList';
import StudentDashboardPanel from './StudentDashboardPanel';
import SubcategoryPanel from './SubcategoryPanel';
import { clearToast } from '../features/users/userSlice';
// import Search from './student/Search';
import { getStudentDashboard } from '../redux/actions/student';
import { API } from '../settings';
import { STUDENT, track, trackWithRole, VIEW_DASHBOARD, } from '../utils/mixpanel';

const PageWrapper = styled.div`
  min-height: 100vh;
  min-height: -webkit-fill-available;
  height: 100vh;
  ${'' /* padding: 25px 0 25px; */}
  position: relative;
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const PageLiner = styled.div`
  padding: 0 25px;
  overflow-y: auto;

  &.noScroll {
    overflow-y: hidden;
  }
`;

const StudentDashboardHeader = styled.h3`
  margin: 0;
  padding-top: 25px;
  font-size: 2.25rem;
`;

const StudentDashboardSubHeader = styled.div`
  margin-top: 0;
  font-size: 1.25rem;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
`;

const LoadingSpinner = styled(Spinner)`
  height: 50px;
  width: 50px;
`;

class StudentDashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      clusters: [],
      percentDone: 0,
      rankedClusters: [],
      subcategories: [],
      showPanel: true,
      showSubcategoryPanel: false,
      skills: [],
      subcategory: {},
    };

    this.togglePanel = this.togglePanel.bind(this);
    this.toggleSubcategoryPanel = this.toggleSubcategoryPanel.bind(this);
  }

  componentDidMount() {
    const { clearToast, user } = this.props;
    const { toastMessage, toastStatus } = user;

    trackWithRole(VIEW_DASHBOARD, { userType: STUDENT});
    
    if (toastMessage && toastStatus) {
      if (toastStatus === 'success') {
        toast.success(toastMessage);
      } else if (toastStatus === 'info') {
        toast.info(toastMessage);
      }

      clearToast();
    }

    API.get(`/students/dashboard`)
      .then((res) => {
        this.setState({
          ...res.data,
          dataLoaded: true,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
        });
        // TODO: handle error
        console.log(error);
      });
  }

  togglePanel() {
    const { showPanel } = this.state;
    this.setState({ showPanel: !showPanel });
  }

  toggleSubcategoryPanel(subcategoryId) {
    const { rankedClusters, showSubcategoryPanel, subcategories } = this.state;
    let subcategory = {};

    if (!showSubcategoryPanel) {
      document.body.classList.add('noScroll');
      this.pageLinerRef.current.classList.add('noScroll');
    } else {
      document.body.classList.remove('noScroll');
      this.pageLinerRef.current.classList.remove('noScroll');
    }

    if (subcategoryId) {
      subcategory = subcategories.find((obj) => obj.id === subcategoryId);
    }

    const cluster = rankedClusters.find(
      (obj) => obj.id === subcategory.clusterId
    );

    this.setState({
      showSubcategoryPanel: !showSubcategoryPanel,
      subcategory,
      skills: cluster.skills,
    });
  }

  render() {
    const {
      clusters,
      dataLoaded,
      loading,
      percentDone,
      rankedClusters,
      showPanel,
      showSubcategoryPanel,
      skills,
      subcategories,
      subcategory,
    } = this.state;

    const { user } = this.props;

    const displayDashboardContent = () => {
      if (loading) {
        return (
          <SpinnerWrapper>
            <LoadingSpinner animation="border" variant="secondary" />
          </SpinnerWrapper>
        );
      } else {
        return (
          <>
            {/*<StudentDashboardSearch />*/}
            {/* <Search /> */}
            <StudentAssessmentCta percentDone={percentDone} />
            <StudentDashboardAllClusters
              clusters={clusters}
              percentDone={percentDone}
            />
            <StudentDashboardRankedClusters
              clusters={clusters}
              rankedClusters={rankedClusters}
              percentDone={percentDone}
            />
            <StudentDashboardSubcategoriesList
              skills={skills}
              clusters={clusters}
              subcategories={subcategories}
              percentDone={percentDone}
              rankedClusters={rankedClusters}
              toggleSubcategoryPanel={this.toggleSubcategoryPanel}
            />
            <SubcategoryPanel
              showPanel={showSubcategoryPanel}
              skills={skills}
              subcategory={subcategory}
              toggleSubcategoryPanel={this.toggleSubcategoryPanel}
              user={user}
            />
            <StudentDashboardPanel
              dataLoaded={dataLoaded}
              percentDone={percentDone}
              showPanel={showPanel}
              togglePanel={this.togglePanel}
            />
          </>
        );
      }
    };
    return (
      <PageWrapper>
        <PageLiner>
          <StudentDashboardHeader data-testid="student-dashboard-header-firstName">Hi {user.firstName},</StudentDashboardHeader>
          <StudentDashboardSubHeader>
            Your great opportunity awaits
          </StudentDashboardSubHeader>
          {displayDashboardContent()}
        </PageLiner>
      </PageWrapper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  studentDashboard: state.student.dashboard,
});
const mapDispatchToProps = {
  clearToast,
  getStudentDashboard,
};
export default connect(mapStateToProps, mapDispatchToProps)(StudentDashboard);
