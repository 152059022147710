import React from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

const data = [
  {
    name: '9th Grade',
    percentOnboarded: 25,
  },
  {
    name: '10th Grade',
    percentOnboarded: 40,
  },
  {
    name: '11th Grade',
    percentOnboarded: 65,
  },
  {
    name: '12th Grade',
    percentOnboarded: 89,
  },
];

const colors = ['#C73D3D', '#FC5A5A', '#FC985A', '#FFC44F', '#FFC44F'];

const xAxisFormatter = (value) => `${value === 13 ? '12+' : value + 'th'} grade`;
const yAxisFormatter = (value) => `${value}%`;

const getPath = (x, y, width, height) => {

  const radius = height === 0 || width < 0 ? 0 : width * 0.3

  return `M ${x} ${y + radius}
  L ${x} ${y + height} 
  L ${x + width} ${y + height}
  L ${x + width} ${y + radius} 
  Q ${x + width} ${y} ${x + width - radius} ${y} 
  L ${x + radius} ${y}
  Q ${x} ${y} ${x} ${y + radius}
  Z`;
};

const BorderBar = (props) => {
  const { fill, x, y, width, height } = props;
  
  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
}

const OnboardingStatus = (props) => {
  let { loading, onboardingByGradeStatus } = props;

  onboardingByGradeStatus = onboardingByGradeStatus?.map((s) => ({...s, percentOnboarded: Math.floor(s?.percentOnboarded) || 0}));

  if (loading) {
    return null;
  }

  return (
    <div
      style={{
        width: '100%',
        maxWidth: '1000px',
        boxShadow: '0 6px 10px 0 rgba(0, 0, 0, 0.02)',
        padding: '20px 0',
        height: '300px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: '20px',
        backgroundColor: '#ffffff',
      }}
    >
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={400}
          height={200}
          data={onboardingByGradeStatus}
          barCategoryGap={25}
        >
          <XAxis tickLine={false} tickMargin={15} tick={{fontSize: 12}} dataKey="grade" tickFormatter={xAxisFormatter} />
          <YAxis tickLine={false} axisLine={false} domain={[0, 100]} tick={{fontSize: 12}} tickFormatter={(yAxisFormatter)} />
          <CartesianGrid vertical={false} strokeDasharray="3 3" />
          <Bar shape={<BorderBar />} dataKey="percentOnboarded" fill="#82ca9d">
            {onboardingByGradeStatus.map((entry, index) => {
              const { percentOnboarded } = entry;
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={colors[Math.floor(percentOnboarded / 25)]}
                />
              );
            })}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default OnboardingStatus;
